
@font-face {
  font-family: 'Minion Pro';
  src: url('./assets/Fonts/minionpro-regular-webfont.woff2') format('woff2'),
       url('./assets/Fonts/minionpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Optional: Additional weights and styles if available */

body {
  overflow-x: hidden;
}

/* Navbar container styling */
.navbar {
position: fixed; /* Fixes the navbar to the top of the viewport */
top: 0;
left: 0;
width: 100%; /* Ensures the navbar spans the full width */
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 20px;
background-color: #ece7dd; /* Light beige background */
border-bottom: 20px solid #b1001f; /* Red bottom border */
z-index: 1000; /* Ensures the navbar stays above other content */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for a slight effect */
flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Logo styling */
.navbar-logo img {
height: 80px;
border: 2px solid #b1001f; /* Red border around logo */
padding: 5px;
background-color: white;
margin-bottom: -30px;
}

/* Menu styling */
.navbar-menu {
list-style: none;
display: flex;
gap: 20px;
margin: 0;
margin-right: 40px;
}

.navbar-menu li {
font-size: 20px; /* Adjust font size as needed */
font-family: 'Nobel', sans-serif; /* Font style */
}

.navbar-menu li a {
text-decoration: none;
color: #333; /* Dark gray text color */
font-weight: bold;
}

.navbar-menu li a:hover {
color: #b1001f; /* Red color on hover */
}

.hamburger {
display: none;
font-size: 24px;
cursor: pointer;
}

/* Media Query for 1920px Width Screens */
@media (min-width: 1920px) {
.navbar {
  padding: 1.5rem 3rem; /* Increase padding for a more spacious look */
}

.navbar-logo img {
  height: 8vh; /* Increase logo size */
  margin-bottom: -2vh; /* Adjust margin */
}

.navbar-menu {
  gap: 1.5vw; /* Increase gap between menu items */
  margin-right: 5vw; /* Adjust right margin */
}

.navbar-menu li {
  font-size: 2vh; /* Slightly larger font size */
}

.hamburger {
  font-size: 20px; /* Increase size of the hamburger icon */
}
}
/* Media Query for iPad (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
.navbar {
  padding: 1rem 1.5rem;
  overflow-x: hidden;
}

.navbar-logo img {
  height: 9vh;
  margin-bottom: -3vh;
}

.navbar-menu {
  gap: 2vw;
  margin-right: 2vw;
}

.navbar-menu li {
  font-size: 2.5vh;
}
}

/* Media Query for iPad Pro 10.5" (834px to 1112px) */
@media (min-width: 834px) and (max-width: 1112px) {
.navbar {
  padding: 1rem 2rem;
  overflow-x: hidden;
}

.navbar-logo img {
  height: 10vh;
  margin-bottom: -3vh;
}

.navbar-menu {
  gap: 1.8vw;
  margin-right: 3vw;
}

.navbar-menu li {
  font-size: 2.8vh;
}
}

/* Media Query for iPad Pro 12.9" (1024px to 1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
.navbar {
  padding: 1.5rem 2.5rem;
  overflow-x: hidden;
}

.navbar-logo img {
  height: 10vh;
  margin-bottom: -3vh;
}

.navbar-menu {
  gap: 2vw;
  margin-right: 3vw;
}

.navbar-menu li {
  font-size: 3vh;
}
}

/* Media Query for iPad Air (820px to 1180px) */
@media (min-width: 820px) and (max-width: 1180px) {
.navbar {
  padding: 0.8rem 1.5rem; /* Reduce padding */
  overflow-x: hidden;
}

.navbar-logo img {
  height: 7vh; /* Further reduced logo size */
  margin-bottom: -1.5vh;
}

.navbar-menu {
  gap: 0.8vw; /* Further reduced gap between items */
  margin-right: 1.5vw;
}

.navbar-menu li {
  font-size: 2.2vh; /* Further reduced font size */
}
}

/* Media Query for iPad Pro 12.9" (1024px to 1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
.navbar {
  padding: 1rem 2rem; /* Adjust padding */
  overflow-x: hidden;
}

.navbar-logo img {
  height: 9vh; /* Reduce logo height slightly */
  margin-bottom: -2.5vh; /* Adjust margin */
}

.navbar-menu {
  gap: 1.5vw; /* Reduce gap between menu items */
  margin-right: 2vw; /* Adjust right margin */
}

.navbar-menu li {
  font-size: 1.5vh; /* Adjust font size */
}
}

/* Media Query for Smaller Screens (Up to 768px) */
@media (max-width: 768px) {
body {
  overflow-x: hidden;  
}

.hamburger {
  display: block;
  margin-right: 25px;
  z-index: 1100;
}

.navbar-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(236, 231, 221, 0.95);
  width: 100%;
  padding: 20px 0;
  flex-direction: column;
  gap: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.navbar-logo img {
  height: 50px;
  border-width: 1px;
  padding: 0px;
  margin-bottom: 5px;
}

.navbar-menu.active {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}

.navbar-menu li {
  font-size: 20px;
  text-align: center;
}
}

/* Media Query for Very Small Screens (Up to 480px) */
@media (max-width: 480px) {
body {
  overflow-x: hidden;  
}
.navbar {
  padding: 1.5rem 3rem; /* Increase padding for a more spacious look */
}

.navbar-logo img {
  height: 6vh; /* Increase logo size */
  margin-bottom: -2vh; /* Adjust margin */
}

.navbar-menu {
   /* Increase gap between menu items */
  margin-right: 5vw; /* Adjust right margin */
}

.navbar-menu li {
  font-size: 2vh; /* Slightly larger font size */
}

.hamburger {
  font-size: 20px; /* Increase size of the hamburger icon */
}
}

@font-face {
  font-family: 'Minion Pro';
  src: url('./assets/Fonts/minionpro-regular-webfont.woff2') format('woff2'),
       url('./assets/Fonts/minionpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* =========================
   Base Styles
========================= */
body {
  overflow-x: hidden;
}

.navbar {
  position: fixed; /* Fix navbar to the top */
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  /* Initially items align left; further positioning in media queries */
  justify-content: flex-start;
  padding: 10px 20px;
  background-color: #ece7dd;
  border-bottom: 20px solid #b1001f;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  overflow: visible; /* Prevent dropdown clipping */
}

.navbar-logo img {
  height: 80px;
  border: 2px solid #b1001f;
  padding: 5px;
  background-color: white;
  margin-bottom: -30px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  margin-right: 40px;
}

.navbar-menu li {
  font-size: 20px;
  font-family: 'Nobel', sans-serif;
}

.navbar-menu li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.navbar-menu li a:hover {
  color: #b1001f;
}

/* By default, hide the hamburger */
.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* =======================================
   iPad-Specific Media Queries & Dropdown
======================================= */

/* ---------- iPad (Regular 9.7" / 10.2") ---------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar {
    padding: 1rem 1.5rem;
    position: relative;
  }
  .navbar-logo img {
    height: 9vh;
    margin-bottom: -3vh;
  }
  .navbar-menu li {
    font-size: 2.5vh;
  }
  /* Adjust hamburger icon position: move slightly left from the far right */
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px; /* move a bit left compared to default */
  }
  /* Dropdown menu styling */
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    /* Align dropdown so it appears to come from the hamburger icon */
    right: 10px;  /* adjust to match the hamburger's offset */
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    /* Make the dropdown emerge from the top right corner */
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------- iPad (Regular 9.7" / 10.2") ---------- */
/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar {
    padding: 1rem 2rem;
    position: relative;
  }
  .navbar-logo img {
    height: 8vh;
    margin-bottom: -2vh;
  }
  .navbar-menu li {
    font-size: 2.2vh;
  }
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
  }
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 10px;
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------- iPad Air ---------- */
/* Portrait */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .navbar {
    padding: 0.8rem 1.5rem;
    position: relative;
  }
  .navbar-logo img {
    height: 7vh;
    margin-bottom: -1.5vh;
  }
  .navbar-menu li {
    font-size: 2.2vh;
  }
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
  }
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 10px;
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------- iPad Air ---------- */
/* Landscape */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .navbar {
    padding: 0.8rem 2rem;
    position: relative;
  }
  .navbar-logo img {
    height: 7vh;
    margin-bottom: -1.5vh;
  }
  .navbar-menu li {
    font-size: 2.2vh;
  }
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
  }
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 10px;
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------- iPad Pro 10.5" ---------- */
/* Portrait */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: portrait) {
  .navbar {
    padding: 1rem 2rem;
    position: relative;
  }
  .navbar-logo img {
    height: 10vh;
    margin-bottom: -3vh;
  }
  .navbar-menu li {
    font-size: 2.8vh;
  }
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
  }
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 10px;
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------- iPad Pro 10.5" ---------- */
/* Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: landscape) {
  .navbar {
    padding: 1rem 2rem;
    position: relative;
  }
  .navbar-logo img {
    height: 9vh;
    margin-bottom: -2.5vh;
  }
  .navbar-menu li {
    font-size: 2.5vh;
  }
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
  }
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 10px;
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------- iPad Pro 12.9" ---------- */
/* Portrait */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .navbar {
    padding: 1.5rem 2.5rem;
    position: relative;
  }
  .navbar-logo img {
    height: 10vh;
    margin-bottom: -3vh;
  }
  .navbar-menu li {
    font-size: 3vh;
  }
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
  }
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 10px;
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------- iPad Pro 12.9" ---------- */
/* Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .navbar {
    padding: 1rem 2rem;
    position: relative;
  }
  .navbar-logo img {
    height: 9vh;
    margin-bottom: -2.5vh;
  }
  .navbar-menu li {
    font-size: 2.5vh;
  }
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
  }
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 10px;
    left: auto;
    width: auto;
    background-color: rgba(236, 231, 221, 0.95);
    padding: 20px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1100;
  }
  .navbar-menu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}



/* Full-width and full-height carousel */
/* Carousel Section */
.carousel {
  position: relative;
  width: 100vw; /* Full viewport width */
  height: 98vh; /* Full viewport height */
  overflow: hidden;
  margin-bottom: 20px;
}

/* Image styling to cover the entire carousel */
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the entire container */
}

.heritage-plus {
color: #FFD700; /* Yellow color for the "+" symbol */
font-weight: bolder; /* Make the "+" slightly bolder */
}

/* White overlay effect */
.white-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* Covers half of the carousel width */
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  z-index: 1;
}


/* Text Overlay Styling */
.text-overlay {
position: absolute;
top: 38%;
left: 2%;
z-index: 2;
color: darkslategray !important; /* Default color */
font-weight: 900 !important;
font-size: 2.2em !important;
font-family: 'Nobel', sans-serif;
text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
}

.red-text {
color: #cc0000 !important; /* Bright red color for alternate text */
}

.next-button {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 3em; /* Adjust font size as needed */
  font-weight: bold; /* Make it bold for a clearer arrow */
  padding: 10px;
  color: white; /* Arrow color */
  z-index: 3;
  cursor: pointer;
  line-height: 1; /* Ensures no extra spacing in the button */
}

.next-button::before {
  content: "›"; /* Unicode character for a clean arrow */
  font-family: Arial, sans-serif; /* Choose a clean, sharp font */
  font-size: 1em; /* Adjust to match the size of the arrow */
}

/* Default styles for larger screens */
/* Carousel styling remains as per your original code */
/* Adjustments for Medium Screens (Laptops and Tablets) */
@media (min-width: 900px) and (max-width: 1280px) {
.carousel {
    height: 80vh; /* Slightly shorter for laptop screens */
    overflow: hidden;
}

.text-overlay {
    top: 40%; /* Adjust text positioning */
    font-size: 1.8em; /* Slightly smaller font size */
}

.next-button {
    font-size: 2.5em; /* Reduce button size for medium screens */
    right: 15px;
}
}
@media (max-width: 768px) {
/* Mobile view adjustments */
.carousel {
  width: 100%; /* Avoid overflow */
  height: 70vh; /* Reduce height for smaller screens */
  margin-bottom: 20px;
  overflow: hidden;
}

/* Carousel image adjustments for mobile */
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* White overlay effect */
.white-overlay {
  width: 60%; /* Wider overlay for better readability on smaller screens */
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

/* Text Overlay Styling adjustments */
.text-overlay {
  top: 42%; /* Reposition slightly higher for mobile */
  left: 5%; /* More padding on left for alignment */
  font-size: 1.5em; /* Scales down font size */
  padding: 10px; /* Add padding for better readability */
}

/* Heading and subheading font sizes */
.text-overlay h1 {
  font-size: 1.5rem; /* Smaller font size for mobile */
}

.text-overlay h2 {
  font-size: 1.2rem; /* Scaled for readability */
}

.text-overlay p {
  font-size: 1.1rem; /* Reduce paragraph font size */
}

/* Next button adjustments */
.next-button {
  font-size: 2em; /* Smaller button size on mobile */
  right: 10px; /* Adjusted spacing */
  top: 45%; /* Slightly lower to align with resized text */
}
.heritage-heading {
  font-size: 2.8rem; /* Smaller font size for mobile */
  margin-top: -5vh; /* Adjusted negative margin */
  margin-bottom: 1vh; /* Reduced bottom margin */
}

.heritage-subheading {
  font-size: 2rem; /* Smaller subheading size */
  color: #555; /* Maintains slightly lighter color */
  font-weight: normal;
  margin: 1vh 0; /* Adjusted margins for mobile */
}

.heritage-plus {
  font-size: 1.8rem; /* Responsive font size for "+" symbol */
  color: #FFD700;
  font-weight: bolder;
}

.heritage-paragraph {
  font-size: 2rem; /* Adjusted paragraph font size for readability on mobile */
  color: #B22222; /* Ensures color consistency */
}

.heritage-paragraph br{
  display: none;
}
}

/* ===================================================
   iPad-Specific Media Queries for the Carousel Section
   (Adjustments for different iPad generations & orientations)
=================================================== */

/* ---------- iPad Regular (9.7"/10.2") Portrait ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
   /* Prevent horizontal overflow */
   html, body {
    overflow-x: hidden;
  }
  
  .carousel {
    /* For iPad portrait, slightly reduce height */
    height: 90vh;
  }
  .text-overlay {
    top: 40%;
    left: 2%;
    font-size: 2.2em; /* Slightly smaller text */
  }
  .next-button {
    font-size: 2.5em;
    right: 15px;
    top: 50%;
  }
  .white-overlay {
    width: 55%; /* Adjust overlay width as needed */
  }
  .heritage-paragraph br{
    display: none;
  }
}

/* ---------- iPad Regular (9.7"/10.2") Landscape ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .carousel {
    /* For landscape, maintain a similar height */
    height: 90vh;
  }
  .text-overlay {
    top: 38%;
    left: 2%;
    font-size: 2.4em;
  }
  .next-button {
    font-size: 2.5em;
    right: 15px;
    top: 48%;
  }
  .white-overlay {
    width: 50%;
  }
  .heritage-paragraph br{
    display: none;
  }
}

/* ---------- iPad Air (Typically 820px to 1180px) Portrait ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .carousel {
    height: 92vh;
  }
  .text-overlay {
    top: 39%;
    left: 3%;
    font-size: 2.3em;
  }
  .next-button {
    font-size: 2.6em;
    right: 20px;
    top: 50%;
  }
  .white-overlay {
    width: 55%;
  }
  .heritage-paragraph br{
    display: none;
  }
}

/* ---------- iPad Air (Typically 820px to 1180px) Landscape ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .carousel {
    height: 92vh;
  }
  .text-overlay {
    top: 37%;
    left: 3%;
    font-size: 2.5em;
  }
  .next-button {
    font-size: 2.6em;
    right: 20px;
    top: 48%;
  }
  .white-overlay {
    width: 50%;
  }
  .heritage-paragraph br{
    display: none;
  }
}

/* ---------- iPad Pro 10.5" Portrait ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: portrait) {
  .carousel {
    height: 95vh;
  }
  .text-overlay {
    top: 38%;
    left: 2%;
    font-size: 2.5em;
  }
  .next-button {
    font-size: 2.8em;
    right: 20px;
    top: 50%;
  }
  .white-overlay {
    width: 50%;
  }
  .heritage-paragraph br{
    display: none;
  }
}

/* ---------- iPad Pro 10.5" Landscape ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: landscape) {
  .carousel {
    height: 95vh;
  }
  .text-overlay {
    top: 37%;
    left: 2%;
    font-size: 2.7em;
  }
  .next-button {
    font-size: 2.8em;
    right: 20px;
    top: 48%;
  }
  .white-overlay {
    width: 50%;
  }
  .heritage-paragraph br{
    display: none;
  }
}

/* ---------- iPad Pro 12.9" Portrait ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .carousel {
    height: 98vh;
  }
  .text-overlay {
    top: 38%;
    left: 2%;
    font-size: 2.7em;
  }
  .next-button {
    font-size: 3em;
    right: 25px;
    top: 50%;
  }
  .white-overlay {
    width: 50%;
  }
  .heritage-paragraph br{
    display: none;
  }
}

/* ---------- iPad Pro 12.9" Landscape ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .carousel {
    height: 98vh;
  }
  .text-overlay {
    top: 37%;
    left: 2%;
    font-size: 2.9em;
  }
  .next-button {
    font-size: 3em;
    right: 25px;
    top: 48%;
  }
  .white-overlay {
    width: 50%;
  }
}


/* Main section container with background image */
.highlight-section {
position: relative;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
background-size: cover;
background-position: center;
padding: 4vw; /* Scaled padding for all screens */
height: 65vh; /* Relative height for responsiveness */
margin-bottom: 2vh;
font-family: 'Nobel', sans-serif;
font-weight: bold;
}

.yellow-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),
  rgba(255, 255, 224, 0.3) 70%,
  transparent 100%
);
z-index: 1;
}

/* Content styling */
.highlight-content {
position: relative;
width: 60%;
z-index: 2;
margin-left: 0.6vw; /* Responsive margin */
}

.highlight-content h1 {
font-size: 2vw; /* Scaled for larger screens */
font-weight: bold;
margin: 0;
color: white;
font-family: 'Nobel', sans-serif;
}

.highlight-content p {
font-size: 1.2vw; /* Adjusts with viewport width */
line-height: 1.5;
color: black;
font-family: 'Nobel', sans-serif;
margin-top: 1vh; /* Relative margin */
}

.highlight-subtext {
font-size: 1.2vw;
font-weight: 600;
color: darkslategrey;
margin-top: 2vh;
font-family: 'Nobel', sans-serif;
margin-left: 5vw;
}

/* Logo styling on the right */
.highlight-logo {
position: relative;
width: 30%;
display: flex;
align-items: center;
justify-content: center;
z-index: 2;
}

.highlight-logo img {
max-width: 80%; /* Restrict logo width for better scaling */
height: auto;
padding: 1vh;
background-color: white;
border-radius: 5px;
}

@media (min-width: 900px) and (max-width: 1280px) {
.highlight-section {
    padding: 3vw; /* Slightly smaller padding */
    height: 60vh;
}

.highlight-content h1 {
    font-size: 1.8vw;
}

.highlight-content p, .highlight-subtext {
    font-size: 1vw; /* Smaller font for better balance */
}

.highlight-logo {
    width: 25%;
}
}
/* Mobile View Adjustments */
@media (max-width: 768px) {
.highlight-section {
  /* flex-direction: column; Stack content vertically */
  height: auto; /* Let content determine height */
  padding: 5vw; /* Adjust padding */
}

.highlight-content {
  width: 100%; /* Full width for content */
  margin-left: 0; /* Center align */
  text-align: center; /*Center text for mobile*/
}

.highlight-content h1 {
  font-size: 5vw; /* Adjust heading size for mobile */
}

.highlight-content p {
  font-size: 3vw; /* Larger for readability */
  line-height: 1.4;
}

.highlight-content br {
  display: none; /* Hide line breaks on mobile */
}

.highlight-subtext {
  font-size: 3.5vw;
  margin-top: 3vh;
  margin-left: 0; /* Center align */
}

.highlight-logo {
  width: 48%; /* Larger width for mobile */
  margin-top: 3vh; /* Add spacing between content and logo */
}

.highlight-logo img {
  max-width: 100%; /* Full width of container */
}
}

/* ===================================================
   iPad-Specific Media Queries for the Highlight Section
   (Further Increased Font Sizes for Enhanced Readability)
=================================================== */

/* ---------- iPad Regular (9.7"/10.2") Portrait ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
   /* Prevent horizontal overflow */
   html, body {
    overflow-x: hidden;
  }
  
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased from 2.5vw */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased from 1.5vw */
  }
  .highlight-subtext {
    margin-left: 4vw;
  }
  .highlight-logo {
    width: 30%;
  }
}

/* ---------- iPad Regular (9.7"/10.2") Landscape ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
   /* Prevent horizontal overflow */
   html, body {
    overflow-x: hidden;
  }
  
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased font size */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased font size */
  }
  .highlight-subtext {
    margin-left: 4vw;
  }
  .highlight-logo {
    width: 30%;
  }
}

/* ---------- iPad Air (820px to 1180px) Portrait ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased font size */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased font size */
  }
  .highlight-subtext {
    margin-left: 5vw;
  }
  .highlight-logo {
    width: 30%;
  }
}

/* ---------- iPad Air (820px to 1180px) Landscape ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased font size */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased font size */
  }
  .highlight-subtext {
    margin-left: 5vw;
  }
  .highlight-logo {
    width: 30%;
  }
}

/* ---------- iPad Pro 10.5" Portrait ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: portrait) {
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased font size */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased font size */
  }
  .highlight-subtext {
    margin-left: 5vw;
  }
  .highlight-logo {
    width: 30%;
  }
}

/* ---------- iPad Pro 10.5" Landscape ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: landscape) {
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased font size */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased font size */
  }
  .highlight-subtext {
    margin-left: 5vw;
  }
  .highlight-logo {
    width: 30%;
  }
}

/* ---------- iPad Pro 12.9" Portrait ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased font size */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased font size */
  }
  .highlight-subtext {
    margin-left: 5vw;
  }
  .highlight-logo {
    width: 30%;
  }
}

/* ---------- iPad Pro 12.9" Landscape ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .highlight-section {
    padding: 4vw;
    height: 65vh;
  }
  .highlight-content {
    width: 60%;
    margin-left: 0.6vw;
  }
  .highlight-content h1 {
    font-size: 3vw;  /* Increased font size */
  }
  .highlight-content p,
  .highlight-subtext {
    font-size: 2vw;  /* Increased font size */
  }
  .highlight-subtext {
    margin-left: 5vw;
  }
  .highlight-logo {
    width: 30%;
  }
}




/* Brands Section Styling */
.brands-section h2 {
font-size: 3rem;
color: #d32f2f;
font-weight: bold;
margin-bottom: 1.25rem; /* 20px */
margin-top: 1.875rem; /* 30px */
font-family: 'Nobel', sans-serif;
}

.brands-gallery {
display: flex;
gap: 1.25rem; /* 20px */
justify-content: center;
align-items: center;
flex-wrap: wrap;
margin-top: 3.125rem; /* 50px */
}

.brands-gallery img {
width: 12.5rem; /* 200px */
height: auto;
border-radius: 0.3125rem; /* 5px */
}

/* Media Query for Very Large Desktops (1920px and above) */
@media (min-width: 1920px) {
.brands-section h2 {
  font-size: 3rem; /* Increase font size for better readability */
  margin-bottom: 1.5rem; /* 24px */
  margin-top: 2.5rem; /* 40px */
}

.brands-gallery {
  gap: 2rem; /* 32px - Increase gap between images */
  margin-top: 4rem; /* 64px */
}

.brands-gallery img {
  width: 10rem; /* 240px - Increase image size */
}
}

/* Media Query for Large Desktops (1440px to 1919px) */
@media (min-width: 1440px) and (max-width: 1919px) {
.brands-section h2 {
  font-size: 2.5rem; /* Increase font size */
  margin-bottom: 1.5rem; /* 24px */
  margin-top: 2rem; /* 32px */
}

.brands-gallery {
  gap: 1.5rem; /* 24px */
  margin-top: 3.5rem; /* 56px */
}

.brands-gallery img {
  width: 9rem; /* 224px - Increase image size */
}
}

/* Media Query for Desktops (1024px to 1439px) */
@media (min-width: 1024px) and (max-width: 1439px) {
.brands-section h2 {
  font-size: 2.5rem; /* Keep original size */
  margin-bottom: 1.25rem; /* 20px */
  margin-top: 1.875rem; /* 30px */
}

.brands-gallery {
  gap: 1.25rem; /* 20px */
  margin-top: 3.125rem; /* 50px */
}

.brands-gallery img {
  width: 12.5rem; /* 200px */
}
}

/* Media Query for Tablets (Up to 1024px) */
@media (max-width: 1024px) {
.brands-section h2 {
  font-size: 2.5rem; /* Reduce font size */
  margin-bottom: 1rem; /* 16px */
  margin-top: 1.5rem; /* 24px */
}

.brands-gallery {
  gap: 1rem; /* 16px - Reduce gap */
  margin-top: 2.5rem; /* 40px */
}

.brands-gallery img {
  width: 10.5rem; /* 168px - Reduce image width */
}
}

/* Media Query for Small Tablets and Large Smartphones (Up to 768px) */
@media (max-width: 768px) {
.brands-section h2 {
  font-size: 2rem; /* Further reduce font size */
  margin-bottom: 0.875rem; /* 14px */
  margin-top: 1.25rem; /* 20px */
}

.brands-gallery {
  gap: 0.875rem; /* 14px - Further reduce gap */
  margin-top: 2rem; /* 32px */
}

.brands-gallery img {
  width: 9rem; /* 144px - Further reduce image width */
}
}

/* Media Query for Mobile Devices (Up to 480px) */
@media (max-width: 480px) {
.brands-section h2 {
  font-size: 1.5rem; /* Smallest font size */
  margin-bottom: 0.75rem; /* 12px */
  margin-top: 1rem; /* 16px */
}

.brands-gallery {
  gap: 0.75rem; /* 12px - Smallest gap */
  margin-top: 1.5rem; /* 24px */
}

.brands-gallery img {
  width: 7.5rem; /* 120px - Smallest image width */
}
}

/* ===================================================
   iPad-Specific Media Queries for the Brands Section
=================================================== */

/* ---------- iPad Regular (9.7"/10.2") Portrait ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .brands-section h2 {
    font-size: 3.5rem;       /* Increased for better readability */
    margin-bottom: 1.5rem;   /* 24px */
    margin-top: 24.5rem;      /* 40px */
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 1.5rem;           /* 24px gap between images */
    margin-top: 4rem;        /* 64px margin-top */
  }
  .brands-gallery img {
    width: 13rem;          /* Increased image width (approx. 208px) */
    height: auto;
    border-radius: 0.3125rem; /* 5px */
  }
}

/* ---------- iPad Regular (9.7"/10.2") Landscape ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .brands-section h2 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    margin-top: 24.5rem;
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 1.5rem;
    margin-top: 4rem;
  }
  .brands-gallery img {
    width: 13rem;
    height: auto;
    border-radius: 0.3125rem;
  }
}

/* ---------- iPad Air Portrait ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .brands-section h2 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    margin-top: 24.5rem;
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 1.5rem;
    margin-top: 4rem;
  }
  .brands-gallery img {
    width: 13rem;
    height: auto;
    border-radius: 0.3125rem;
  }
}

/* ---------- iPad Air Landscape ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .brands-section h2 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    margin-top: 24.5rem;
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 1.5rem;
    margin-top: 4rem;
  }
  .brands-gallery img {
    width: 13rem;
    height: auto;
    border-radius: 0.3125rem;
  }
}

/* ---------- iPad Pro 10.5" Portrait ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: portrait) {
  .brands-section h2 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    margin-top: 24.5rem;
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 1.5rem;
    margin-top: 4rem;
  }
  .brands-gallery img {
    width: 13rem;
    height: auto;
    border-radius: 0.3125rem;
  }
}

/* ---------- iPad Pro 10.5" Landscape ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: landscape) {
  .brands-section h2 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    margin-top: 24.5rem;
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 1.5rem;
    margin-top: 4rem;
  }
  .brands-gallery img {
    width: 13rem;
    height: auto;
    border-radius: 0.3125rem;
  }
}

/* ---------- iPad Pro 12.9" Portrait ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .brands-section h2 {
    font-size: 3.75rem;    /* Slightly larger for the Pro 12.9" */
    margin-bottom: 1.75rem;  /* 28px */
    margin-top: 24.75rem;     /* 44px */
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 2rem;             /* Increased gap */
    margin-top: 4.5rem;
  }
  .brands-gallery img {
    width: 14rem;          /* Increased image width */
    height: auto;
    border-radius: 0.3125rem;
  }
}

/* ---------- iPad Pro 12.9" Landscape ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .brands-section h2 {
    font-size: 3.75rem;
    margin-bottom: 1.75rem;
    margin-top: 24.75rem;
    font-family: 'Nobel', sans-serif;
    color: #d32f2f;
    font-weight: bold;
  }
  .brands-gallery {
    gap: 2rem;
    margin-top: 4.5rem;
  }
  .brands-gallery img {
    width: 14rem;
    height: auto;
    border-radius: 0.3125rem;
  }
}

/* Main container setup */
/* Main container setup */
.what-we-do-section {
display: flex;
background: linear-gradient(to right, #9bf5e6 0%, rgba(165, 180, 178, 0.85) 40%, rgba(152, 153, 153, 0) 100%);
padding: 1.5625rem; /* 25px */
gap: 3.125rem; /* 50px */
align-items: flex-start;
margin-top: 0.9375rem; /* 15px */
margin-bottom: 0;
box-sizing: border-box;
max-width: 100vw;
}

/* Main image container */
.main-image-container {
position: relative;
flex: 1;
display: flex;
align-items: center;
height: auto;
overflow: hidden;
max-width: 40rem; /* 800px */
margin-left: 4.375rem; /* 70px */
margin-top: 1.875rem; /* 30px */
}

.main-image {
width: 100%;
height: auto;
}

/* Overlay with gradient effect */
.main-overlay {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
display: flex;
align-items: center;
justify-content: center;
border-radius: 0.5rem; /* 8px */
}

.main-title {
color: white;
font-size: 3rem;
font-weight: bold;
text-align: center;
z-index: 1;
}

/* Text content */
.text-content {
width: 23%;
padding: 0.625rem; /* 10px */
font-family: 'Nobel', sans-serif;
font-size: 1.5rem;
line-height: 1.4;
display: flex;
flex-direction: column;
justify-content: center;
box-sizing: border-box;
}

/* Sidebar container */
.sidebar-container {
width: 15%;
display: flex;
flex-direction: column;
align-items: center;
overflow: hidden;
}

.sidebar {
display: flex;
flex-direction: column;
width: 100%;
gap: 0;
}

/* Sidebar items */
.sidebar-item {
display: flex;
flex-direction: column;
overflow: hidden;
height: 16.25rem; /* 260px */
border-radius: 0;
margin: 0;
}

/* Sidebar images */
.sidebar-item img {
width: 100%;
height: 11.375rem; /* 182px */
object-fit: cover;
}

/* Sidebar caption */
.sidebar-caption {
background: darkslategray;
color: white;
font-size: 1rem;
padding: 0.1875rem 0.5625rem; /* 3px 9px */
text-align: left;
box-sizing: border-box;
font-family: 'Nobel', sans-serif;
margin: 0;
height: 6.25rem; /* 100px */
}

/* For dimensions 1536x864 */
@media (min-width: 1536px) {
.what-we-do-section {
  padding: 2rem; /* Adjust padding */
  gap: 4rem; /* Adjust gap */
  max-width: 100%; /* Prevent overflow */
 
}

.main-image-container {
  max-width: 42rem; /* Slightly larger max-width */
  margin-left: 5rem; /* Adjust margin for alignment */
}

.main-title {
  font-size: 3.5rem; /* Increase font size */

}

.text-content {
  font-size: 1.71rem; /* Larger text for better readability */
  width: 25%; /* Increase width for content */
  
}

.sidebar-container {
  width: 17%; /* Increase width */

}

.sidebar-item {
  height: 17rem; /* Slightly taller items */
 
}

.sidebar-caption {
  font-size: 1.1rem; /* Increase caption font size */
  
}
}

/* For dimensions 1440x900 */
@media (min-width: 1440px) and (max-width: 1536px) {
.what-we-do-section {
  padding: 1.8rem; /* Adjust padding */
  gap: 3.5rem; /* Adjust gap */
  max-width: 100%; /* Prevent overflow */

}

.main-image-container {
  max-width: 45rem; /* Slightly smaller max-width */
  margin-left: 4.5rem; /* Adjust margin */
}

.main-title {
  font-size: 3.2rem; /* Adjust font size */
}

.text-content {
  font-size: 1.7rem;
  width: 24%; /* Slightly smaller width */
}

.sidebar-container {
  width: 16%; /* Adjust width */
}

.sidebar-item {
  height: 17rem; /* Adjust item height */
}

.sidebar-caption {
  font-size: 1rem; /* Maintain readable size */
}
}

/* For dimensions 1366x768 */
@media (min-width: 1366px) and (max-width: 1440px) {
.what-we-do-section {
  padding: 1.5rem; /* Reduce padding */
  gap: 3rem; /* Reduce gap */
  max-width: 100%; /* Prevent overflow */

}

.main-image-container {
  max-width: 40rem; /* Reduce max-width */
  margin-left: 4rem; /* Adjust margin */
}

.main-title {
  font-size: 3rem; /* Reduce font size */
}

.text-content {
  font-size: 1.5rem; /* Reduce text size */
  width: 23%; /* Adjust width */
}

.sidebar-container {
  width: 16%; /* Maintain proportion */
}

.sidebar-item {
  height: 16.2rem; /* Adjust height */
}

.sidebar-caption {
  font-size: 0.92rem; /* Slightly smaller font */
}
}


/* For screens up to 1024px (tablets) */
@media (max-width: 1024px) {
.what-we-do-section {
  flex-direction: column;
  align-items: center;
  
}

.text-content, .sidebar-container {
  width: 100%;
  margin-top: 20px;
}


.main-image-container {
  margin-left: 0;
}
}

/* For screens up to 768px (mobile) */
@media (max-width: 768px) {
.main-title {
  font-size: 1.5rem;
}

.text-content {
  font-size: 1rem;
}

.sidebar-caption {
  font-size: 0.65rem;
}
}

/* For screens up to 480px (small mobile devices) */
@media (max-width: 480px) {
.main-title {
  font-size: 1.2rem;
}
.text-content{
  margin-top: -5px;
}
.sidebar-container{
  margin-top:-20px;
}

.text-content {
  font-size: 0.9rem;
}

.sidebar-caption {
  font-size: 0.68rem;
}

.sidebar-item {
  height: 220px;
}

.sidebar-item img {
  height: 180px;
}
}

/* For iPhone 12 Pro (390px x 844px) */
@media (max-width: 390px) and (max-height: 844px) {
.sidebar-item {
  height: 240px; /* Increased height for iPhone 12 Pro */
}

.sidebar-item img {
  height: 190px; /* Increased image height */
}

.sidebar-caption {
  height: 50px; /* Adjusted caption height */
  font-size: 0.7rem; /* Slightly increased font size */
}
}

/* For iPhone XR (414px x 896px) */
@media (max-width: 414px) and (max-height: 896px) {
.sidebar-item {
  height: 230px; /* Increased height for the sidebar item */
}

.sidebar-item img {
  height: 190px; /* Increased image height */
}

.sidebar-caption {
  height: 50px; /* Adjusted caption height */
  font-size: 0.7rem; /* Slightly increased font size for better readability */
}

.main-title {
  font-size: 1.3rem; /* Adjusted font size for the title */
}

.text-content {
  font-size: 1rem; /* Slightly increased text size */
}
}

/* ===================================================
   iPad-Specific Media Queries for the What-We-Do Section
   – Column Layout with Increased Sidebar Image Height and Reduced Caption Area
=================================================== */

/* ---------- iPad Regular (9.7"/10.2") Portrait ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.6rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
    margin-top: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  /* Increase sidebar image height */
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  /* Reduce caption area */
  .sidebar-caption {
    height: 3.5rem;       /* Reduced caption height */
    font-size: 1.1rem;
    margin: 0;
    padding: 0.32rem;    /* Adjust padding as needed */
  }
}

/* ---------- iPad Regular (9.7"/10.2") Landscape ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.6rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
    margin-top: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  .sidebar-caption {
    height: 3.5rem;
    font-size: 1.1rem;
    margin: 0;
    padding: 0.32rem;
  }
}

/* ---------- iPad Air (820px to 1180px) Portrait ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.6rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
    margin-top: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  .sidebar-caption {
    height: 3.5rem;
    font-size: 1.1rem;
    margin: 0;
    padding: 0.32rem;
  }
}

/* ---------- iPad Air (820px to 1180px) Landscape ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.6rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
    margin-top: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  .sidebar-caption {
    height: 3.5rem;
    font-size: 1.1rem;
    margin: 0;
    padding: 0.32rem;
  }
}

/* ---------- iPad Pro 10.5" Portrait ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: portrait) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 2.8rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.7rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  .sidebar-caption {
    height: 3.5rem;
    font-size: 1.0rem;
    margin: 0;
    padding: 0.32rem;
  }
}

/* ---------- iPad Pro 10.5" Landscape ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: landscape) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 2.8rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.7rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  .sidebar-caption {
    height: 3.5rem;
    font-size: 1.0rem;
    margin: 0;
    padding: 0.32rem;
  }
}

/* ---------- iPad Pro 12.9" Portrait ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 3rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.7rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  .sidebar-caption {
    height: 3.5rem;
    font-size: 1.1rem;
    margin: 0;
    padding: 0.32rem;
  }
}

/* ---------- iPad Pro 12.9" Landscape ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .what-we-do-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .main-image-container {
    max-width: 100%;
    margin: 0;
  }
  .main-title {
    font-size: 3rem;
    text-align: center;
    margin: 0;
  }
  .text-content {
    width: 90%;
    text-align: center;
    font-size: 1.7rem;
    margin: 0;
    padding: 0.5rem;
  }
  .sidebar-container {
    width: 80%;
    margin: 0;
  }
  .sidebar-item {
    height: auto;
    margin: 0;
  }
  .sidebar-item img {
    height: 22rem;
    object-fit: cover;
    width: 100%;
  }
  .sidebar-caption {
    height: 3.5rem;
    font-size: 1.1rem;
    margin: 0;
    padding: 0.32rem;
  }
}


.about-us-page {
width: 100%;
/* padding: 20px 20px 0 20px; */
background: linear-gradient(to right, rgba(139, 0, 0, 0.8), rgba(255, 255, 0, 0));
}

.about-us-page-section {
display: flex;
align-items: center;
margin-bottom: 0px;
margin-top: 40px;
}

.red-color{
background: linear-gradient(to right, rgba(139, 0, 0, 0.8), rgba(255, 255, 0, 0));
}

.main-image-container-about {
margin-top: 90px;
width: 50%;
padding-right: 20px;
object-fit: cover;
margin-left: 180px;
}

.main-image-about {
width: 100%;
height: auto;
border-radius: 0px;
object-fit: cover;
}


.about-us-page-content {
width: 50%;
}

.about-us-page-heading {
font-size: 3.6rem; /* Adjusted for a bold heading */
font-weight: bolder;
color: darkslategray;
font-family: 'Nobel', sans-serif;
text-transform: uppercase;
margin-bottom: 5px;
margin-top: -150px;

}

.about-us-page-subheading {
font-size: 3.3rem;
color: #555; /* Slightly lighter color */
font-family: 'Nobel', sans-serif;
font-weight: normal;
margin: 5px 0;
}

.about-us-page-plus {
color: #FFD700; /* Yellow color for the "+" symbol */
font-weight: bolder; /* Make the "+" slightly bolder */
}

.about-us-page-content p {
font-size: 1.5rem;
line-height: 1.5;
color: #555;
font-family: 'Nobel', sans-serif;
margin-top: 20px;
font-weight: bold;
}

/* Container to hold timeline and pillars side-by-side */
/* Container for Info Section */
/* Info Container */
.info-container {
display: flex;
justify-content: center;
gap: 150px;
margin-top: 17px; /* Adjust as needed */
padding-bottom: 20px; /* Add spacing to ensure alignment at the bottom */
}

/* Timeline Section */
.timeline-section {
display: flex;
gap: 10px;
align-items: center;
justify-content: center;
margin-top: auto; /* Pushes timeline items towards the bottom */
padding-bottom: 20px; /* Adjust for more space at the bottom */
margin-left: 130px;
}


/* Individual Timeline Item */
.timeline-item {
position: relative;
text-align: center;
}


.timeline-item img {
width: 260px; /* Adjusted width */
height: 340px; /* Adjusted height for a more uniform look */
object-fit: cover;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
border-radius: 0;
padding: 0;
}
.timeline-item:nth-child(2) img {
width: 410px; /* Increased width for the second image */
}



/* Position years on alternating sides */
.timeline-item:nth-child(odd) p {
position: absolute;
top: 50%;
left: -60px; /* Distance from image */
transform: translateY(-50%);
font-size: 1.6rem;
font-weight: bold;
color: #FFFFFF;
font-family: 'Nobel', sans-serif;
}

.timeline-item:nth-child(even) p {
position: absolute;
top: 50%;
right: -60px; /* Distance from image */
transform: translateY(-50%);
font-size: 1.6rem;
font-weight: bold;
font-family: 'Nobel', sans-serif;
color: #4a4a4a; /* Dark color for contrast */
}

/* Pillars Section Styling */
/* Pillars Section */
/* Pillars Section */
.pillars-section {
text-align: center;
margin-top: -50px; /* Adds space above the "Our Pillars of Success" */
padding-bottom: 40px; /* Ensures alignment with the bottom border */
margin-left: 90px;
}

.pillars-container {
display: flex;
justify-content: space-around;
gap: 50px;

}

.pillars-section h2 {
font-size: 2.4rem;
font-weight: bold;
color: darkslategray;
margin-bottom: 30px;
font-family: 'Nobel', sans-serif;
margin-left: 50px;
}


.pillar {
width: 100px;
text-align: center;
 /* Reduce margin to bring items closer */
}


.pillar img {
width: 140px;
height: 160px;
border-radius: 50%;
object-fit: fill;
font-family: 'Nobel', sans-serif;
position: relative;
left:-20px
}

.pillar p {
margin-top: 13px;
font-size: 1.3rem;
color: #333;
font-weight: bold;
font-family: 'Nobel', sans-serif;
}



/* Base styles for desktop (unchanged from your provided code) */

/* Tablet view adjustments */
/* Base styles for desktop (unchanged from your provided code) */

/* Laptop screen adjustments */
/* Styles for screens with a max-width of 1920px */
@media (max-width: 1920px) {
.about-us-page {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding: 15px 15px 0 15px;
}

.about-us-page-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 0px;
}

.main-image-container-about {
  margin-top: 70px;
  width: 45%;
  padding-right: 15px;
  margin-left: 0; /* Reset margin */
}

.timeline-section {
  display: flex;
  gap: 20px; /* Increase spacing for better alignment */
  margin-left: 0; /* Remove hardcoded margin */
  padding: 0 15px; /* Add padding for alignment */
  justify-content: center; /* Center align the timeline */
}

.timeline-item img {
  width: 200px;
  height: 250px;
}

.pillars-section {
  text-align: center;
  margin-top: 20px; /* Add spacing */
  padding-bottom: 30px;
  margin-left: 0; /* Remove margin */
}

.pillars-container {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.pillar img {
  width: 110px;
  height: 130px;
}
}

@media (min-width: 1200px) {
.about-us-page {
  padding: 10px 80px; /* Add more padding for larger screens */
}

.about-us-page-heading {
  font-size: 3rem; /* Increase heading size */
  text-align: left; /* Align heading to the left */
  margin-top: 10px; /* Add margin for better spacing */
}

.about-us-page-subheading {
  font-size: 2.6rem !important; /* Increase subheading size */
  text-align: left;
  margin-bottom: 20px;
}

.about-us-page-content {
  max-width: 800px; /* Set the width of the entire content area */
  margin: 0 auto; /* Center the content on the page */
  text-align: left; /* Ensure all text is left-aligned */
}

.about-us-page-content h1,
.about-us-page-content h2 {
  margin: 0; /* Remove extra margins for tighter alignment */
}

.about-us-page-content h2 {
  font-size: 2rem; /* Adjust subheading size */
  font-weight: 600; /* Set weight for subheading */
  color: #333;
}

.about-us-page-content p {
  font-size: 1.2rem; /* Adjust font size for readability */
  line-height: 1.6; /* Add space between lines */
  margin-top: 15px; /* Add spacing below the subheading */
  margin-bottom: 0; /* Ensure no unnecessary bottom margin */
  text-align: left; /* Align paragraph text to the left */
  margin-right: 60px !important;
}



.main-image-container-about {
  width: 50%; /* Reduce the width for better alignment */
  margin-left: 0; /* Align it properly */
}

.info-container {
  display: flex;
  flex-direction: row; /* Keep timeline and content side by side */
  gap: 40px; /* Add spacing between sections */
  justify-content: space-between; /* Spread content across the page */
  margin-top: 40px;
}

/* Timeline section adjustments */
.timeline-section {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  gap: 20px;
  align-items: flex-start; /* Align items to the top */
}

.timeline-item {
  width: 200px; /* Set consistent width */
  text-align: center;
}

.timeline-item img {
  width: 100%; /* Ensure images fit within the container */
  height: auto;
  margin-bottom: 10px; /* Add spacing below the image */
}

/* Pillars Section */
.pillars-section {
  margin-top: 60px; /* Add spacing above the section */
  text-align: center; /* Center align the section heading */
}

.pillars-section h2 {
  font-size: 2.5rem; /* Adjust heading size */
  margin-bottom: 40px; /* Add spacing below the heading */
}

.pillars-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Show all pillars in one row */
  gap: 20px; /* Add spacing between pillars */
  justify-content: center;
}

.pillar img {
  width: 150px; /* Ensure images are uniform */
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.pillar p {
  font-size: 1rem;
  text-align: center;
  margin: 0;
}
}

@media (min-width: 1024px) {
.info-container {
  display: flex;
  justify-content: space-between; /* Spread timeline and pillars */
  gap: 80px; /* Adjust spacing */
  margin-top: -40px;
  padding-bottom: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; /* Center the container */
}

/* Timeline Section */
.timeline-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust spacing between items */
  padding-bottom: 20px;
  margin-left: -50px; /* Center aligns timeline items */
  width: 100%;
}

.timeline-item {
  position: relative;
  text-align: center;
  flex: 1; /* Make timeline items responsive */
}

.timeline-item img {
  width: 200px;
  height: 280px;
  object-fit: cover;
  border-radius: 0px; /* Add slight rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.timeline-item:nth-child(2) img {
  width: 330px; /* Ensure all images are consistent in size */
}

.timeline-item p {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.6rem;
  font-weight: bold;
  font-family: 'Nobel', sans-serif;
}

.timeline-item:nth-child(odd) p {
  left: -60px; /* Distance for left-side years */
  color: #FFFFFF;
}

.timeline-item:nth-child(even) p {
  right: -185px; /* Distance for right-side years */
  color: #4a4a4a;
}

.pillars-section {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 40px;
  margin-left: 100px; /* Move the entire section to the right */
}

.pillars-container {
  display: flex;
  justify-content: flex-start; /* Keep items aligned to the left */
  gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 80px; /* Add padding to push items further right */
}

.pillars-section h2 {
  font-size: 2.4rem;
  font-weight: bold;
  color: darkslategray;
  margin-bottom: 30px;
  text-align: center; /* Align the title text to the left */
  margin-left: 60px; /* Adjust heading position */
}

.pillar {
  text-align: center;
  flex: 1 1 150px; /* Set minimum width for each pillar */
}

.pillar img {
  width: 100px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}

.pillar p {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-right:60px ;
}
}

/* Extra small mobile view adjustments */
@media (max-width: 480px) {
.about-us-page {
  padding: 5px;
  overflow-y: auto; /* Ensure vertical scrolling */
}

.about-us-page-heading {
  font-size: 2rem;
}

.about-us-page-subheading {
  font-size: 1.8rem;
}

.about-us-page-content p {
  font-size: 0.9rem;
  text-align: justify;
}

.main-image-container-about {
  width: 100%;
  padding: 0;
}

.timeline-item img {
  width: 150px;
  height: 200px;
  display: block; /* Ensure image is displayed */
  visibility: visible; /* Ensure the image is visible */
}

.timeline-item p {
  font-size: 1.2rem;
  position: relative;
  top: -10px;
}

.pillars-section h2 {
  font-size: 1.6rem;
}

.pillar img {
  width: 80px;
  height: 100px;
  display: block; /* Ensure image is displayed */
  visibility: visible; /* Ensure the image is visible */
}

.pillar p {
  font-size: 0.9rem;
}

.red-overlay {
  height: 100%;
  background: linear-gradient(to right, rgba(139, 0, 0, 0.8), rgba(139, 0, 0, 0) 30%);
  z-index: 0; /* Ensure overlay stays behind other elements */
}
}



/* Evolution Section */
.evolution-section {
display: flex;
justify-content: space-between;
gap: 1px;
padding: 80px 20px;
background: linear-gradient(to right, rgba(139, 0, 0, 0.8), rgba(255, 255, 0, 0));
color: #FFF;
border-radius: 0px;
margin-top: 30px;
margin-left: -20px;
}


.evolution-title {
font-size: 55px;
color: #d9d9d9;
margin-bottom: 40px; /* Space between title and timeline */
text-align: center;
margin-left: 380px;
font-family: 'Nobel', sans-serif;
}

.evolution-container {
display: flex;
flex-direction: column;
align-items: flex-start; /* Align content to the left */
width: 60%; /* Adjust as needed to control container width */
margin-left: -50px; /* Move the whole container further to the left */
}
/* Timeline in Evolution Section */
.evolution-timeline {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
text-align: center;
}
.evolution-timeline h2 {
font-size: 50px;
color: #d9d9d9;
margin-bottom: 20px;
}

.evolution-period {
display: flex;
flex-direction: column;
align-items: center;
margin: 20px 0;
}

.timeline-evolution {
display: flex;
flex-direction: column;
align-items: center;
margin: 0 20px;
position: relative;
}

.timeline-line {
width: 240px; /* Adjust to control line length */
height: 5px;
background-image: linear-gradient(to right, #FFF 25%, transparent 0%);
background-size: 13px 4px;
background-repeat: repeat-x;
}

.timeline-label {
position: absolute;
top: 20px; /* Adjust for text positioning above the line */
font-weight: bold;
font-size: 20px;
color: white;
background-color:  transparent; /* Background color to blend in with page */
padding: 0 5px; /* Add padding to fit the label text */
white-space: nowrap;
font-family: 'Nobel', sans-serif;
}

.evolution-period img {
width: 140px;
height: 140px;
border-radius: 50%;
object-fit: cover;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.evolution-period p {
margin-top: 10px;
font-size: 25px;
color: #FFF;
font-family: 'Nobel', sans-serif;
}

/* Details Section for Evolution Items */
.evolution-details {
display: flex;
flex-direction: column;
gap: 20px;
width: 45%; /* Adjust width as needed to align to the right */
padding-left: 10px; /* Add space between the timeline and details */
margin-left: auto; /* Pushes the details section to the right */
}

.evolution-item {
display: flex;
align-items: flex-start;
}

.evolution-item img {
width: 270px; /* Fixed width */
height: 270px; /* Fixed height */
object-fit: cover;
margin-right: 20px;
flex-shrink: 0; /* Prevents image from resizing */
}

.evolution-item div {
max-width: 450px; /* Set a fixed width for text to align uniformly */
}

.evolution-item h3 {
font-size: 28px;
color: darkslategray;
margin: 0;
font-family: 'Nobel', sans-serif;
}

.evolution-item p {
font-size: 17px;
line-height: 1.5;
margin: 5px 0 0;
color: black;
font-family: 'Nobel', sans-serif;
}

@media (min-width: 1920px) {
.evolution-section {
  display: flex;
  justify-content: space-between;
  gap: 30px; /* Increased gap for larger screens */
  padding: 100px 40px; /* Adjust padding for larger dimensions */
  background: linear-gradient(to right, rgba(139, 0, 0, 0.8), rgba(255, 255, 0, 0));
  color: #FFF;
  border-radius: 0;
  margin-top: 40px;
  margin-left: 0; /* Align to the viewport */
}

.evolution-title {
  position: relative; /* Allow precise positioning */
  font-size: 65px; /* Adjust font size for larger screens */
  color: #d9d9d9;
  margin-bottom: 50px; /* Space below the title */
  font-family: 'Nobel', sans-serif;
  left: 100px; /* Shift the title to the right */
  text-align: left; /* Align text properly */
}

.evolution-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 65%; /* Adjust width for better layout */
  margin-left: 20px; /* Slightly offset to align visually */
}

.evolution-timeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  gap: 40px; /* Increased gap between timeline items */
}

.timeline-line {
  width: 300px; /* Extend line length */
  height: 6px;
  background-image: linear-gradient(to right, #FFF 30%, transparent 0%);
  background-size: 15px 6px;
  background-repeat: repeat-x;
}

.timeline-label {
  font-size: 24px; /* Larger label font size */
  padding: 2px 8px; /* Adjust padding */
}

.evolution-period img {
  width: 200px; /* Larger image size */
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
}

.evolution-period p {
  margin-top: 15px; /* Increased spacing */
  font-size: 28px; /* Larger font size */
  color: #FFF;
  font-family: 'Nobel', sans-serif;
}

.evolution-details {
  display: flex;
  flex-direction: column;
  gap: 30px; /* Increased gap for better spacing */
  width: 50%; /* Adjust width */
  padding-left: 20px; /* Adjust spacing */
  margin-left: auto;
}

.evolution-item img {
  width: 320px; /* Larger image size */
  height: 320px;
  margin-right: 30px; /* Increased margin */
}

.evolution-item div {
  max-width: 600px; /* Wider text container */
}

.evolution-item h3 {
  font-size: 35px; /* Larger heading size */
  margin-bottom: 10px;
}

.evolution-item p {
  font-size: 20px; /* Larger font size for readability */
  line-height: 1.8;
}
}

/* Base styles for desktop (unchanged from your provided code) */

/* Tablet view adjustments */
/* Large Screens (1024px and above) */
@media (min-width: 1024px) {
.evolution-section {
  flex-direction: row; /* Side-by-side layout */
  gap: 40px; /* Space between sections */
  background: linear-gradient(to right, rgba(139, 0, 0, 0.8), rgba(255, 255, 0, 0));
}

.evolution-title{
  font-size: 38px;
  margin-left: 220px;
}

 .evolution-period p{
  font-size: 20px;
 } 
.evolution-container {
  width: 50%; /* Timeline occupies 50% */
}


.evolution-details {
  width: 45%; /* Details section occupies 45% */
}

.timeline-line {
  width: 200px; /* Longer dotted line */
}

.evolution-item img {
  width: 160px;
  height: 180px; /* Larger images for desktop */
}
}

/* Medium Screens (768px - 1024px) */
@media (max-width: 1024px) {
.evolution-section {
  flex-direction: column; /* Stack sections vertically */
  gap: 20px; /* Reduced gap */
}

.evolution-container,
.evolution-details {
  width: 100%; /* Full width to align properly */
}

.timeline-line {
  width: 160px; /* Shorter dotted line */
}

.evolution-item img {
  width: 140px;
  height: 140px; /* Slightly smaller images */
}

.evolution-item div {
  padding: 0 10px; /* Add padding around text */
}
}

/* General fixes for the About Us page */
@media (max-width: 768px) {
.about-us-page {
  /* padding: 10px; */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
}

.about-us-page-section {
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center the content */
  text-align: center; /* Center-align the text */
  margin-bottom: 20px; /* Add some spacing below the section */
}
.red-color{
  background: linear-gradient(to right, rgba(139, 0, 0, 0.8), rgba(255, 255, 0, 0));
}

.main-image-container-about {
  width: 90%; /* Reduce image width to fit smaller screens */
  margin-bottom: 15px; /* Add spacing below the image */
}

.main-image-about {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the image covers the container proportionally */
  border-radius: 10px; /* Optional: Add rounded corners for better aesthetics */
}

.about-us-page-heading {
  font-size: 2rem; /* Adjust heading size for smaller screens */
  margin: 10px 0; /* Reduce margin above and below */
}

.about-us-page-subheading {
  font-size: 1.5rem;
  margin: 5px 0;
}

.about-us-page-content {
  width: 100%;
  padding: 0 15px; /* Add some horizontal padding */
}

.about-us-page-content p {
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
  margin: 0 auto 15px; /* Adjust spacing below the paragraph */
  max-width: 90%; /* Limit paragraph width for better readability */
}
/* Timeline Section Adjustments */
.info-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align the content */
  gap: 20px; /* Add spacing between elements */
  margin: 20px auto; /* Center the container with vertical spacing */
  width: 90%; /* Adjust width to fit within the viewport */
  text-align: center; /* Center align the text content */
}

/* Timeline Section Adjustments */
.timeline-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Add spacing between timeline items */
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Adjust the first and second timeline images separately */
.timeline-item:nth-child(2) img {
  width: 180px; /* Smaller width for the second image */
  height: auto;
}

.timeline-item img {
  width: 150px; /* Default size for other images */
  height: auto;
  border-radius: 8px; /* Optional: Add rounded corners */
}

/* Style the year below the images */
.timeline-item p {
  margin-top: 8px; /* Add spacing above the year label */
  font-size: 0.9rem;
  font-weight: bold;
  color: darkslategray;
  text-align: center;
}

/* Ensure proper spacing and alignment */
.timeline-section .timeline-item:nth-child(2) p {
  font-size: 0.85rem; /* Slightly smaller font size for the year */
  margin-top: 6px; /* Adjust spacing for the second timeline item */
}
/* Pillars Section Adjustments */
.pillars-section {
  text-align: center;
  padding: 20px 10px;
}

.pillars-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: darkslategray;
}

.pillars-container {
  display: flex;
  flex-wrap: wrap; /* Ensure rows wrap for multiple pillars */
  justify-content: center; /* Center the grid items */
  gap: 20px; /* Space between the pillars */
}

.pillar {
  display: flex;
  flex-direction: column; /* Stack image and text */
  align-items: center; /* Center both image and text horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Center text */
  width: 45%; /* Adjust pillar width to fit two per row */
  margin: 0 auto; /* Ensure alignment */
}

.pillar img {
  width: 120px; /* Ensure uniform image size */
  height: 120px;
  object-fit: cover;
  border-radius: 50%; /* Make images circular */
  margin-bottom: 10px; /* Add spacing between image and text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional for better visuals */
}

.pillar p {
  font-size: 1rem;
  font-weight: bold;
  color: darkslategray;
  margin: 0; /* Remove unwanted spacing */
  line-height: 1.4;
  text-align: center; /* Center text under the image */
  width: 100%; /* Ensure alignment spans full container width */
  display: block;
}

/* Special styling for the last pillar */
.pillar:nth-child(5) {
  width: 100%; /* Ensure the last pillar spans the full row */
  text-align: center; /* Center the text */
  margin: 0 auto;
}

/* Evolution Section Adjustments */
.evolution-section {
  margin-top: 20px; /* Adjust spacing above this section */
}

.evolution-title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.evolution-timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Adjust gap below the timeline */
}

.evolution-period img {
  width: 100px;
  height: auto;
}

.evolution-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Adjust spacing between evolution items */
}

.evolution-item img {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.evolution-item h3 {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 5px;
}

.evolution-item p {
  font-size: 0.8rem;
  line-height: 1.4;
  text-align: center;
  padding: 0 10px;
}
}


/* Extra Small Screens (<480px) */
@media (max-width: 480px) {

.evolution-timeline .evolution-period:first-child {
  margin-left: 80px; /* Move it slightly to the right */
}
.evolution-title {
  font-size: 1.8rem; /* Compact title */
  margin-left: 80px; /* Centers text horizontally */
}

.timeline-line {
  width: 50px; /* Very short dotted line */
}
.timeline-label{
  font-size: 1rem;
}
.evolution-container{
      align-items: center;
}
.evolution-period p{
  font-size: 1rem;
}
.evolution-period img {
  width: 100px;
  height: 120px; /* Compact images */
  margin: 0 auto; 
}

.evolution-item img {
  width: 100px;
  height: 100px;
}

.evolution-item h3 {
  font-size: 0.9rem; /* Smaller headers */
}

.evolution-item p {
  font-size: 0.8rem; /* Compact paragraph text */
}

.evolution-item {
  gap: 10px; /* Reduce spacing */
  text-align: center; /* Center text for mobile */
}
}



/* Banner Section with Darker Gradient Overlay */
/* Values Page Styling */
.values-page {
font-family: 'Nobel', sans-serif;
color: #333;
text-align: center;
width: 100%;
overflow: hidden;
}

/* Banner Section */
.values-banner {
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
padding: 60px 20px;
background-size: cover;
background-repeat: no-repeat;
background-position: center 20%;
width: 100vw;
height: 70vh;
box-sizing: border-box;
margin: 0;
overflow: hidden;
}

/* Dark overlay using ::before pseudo-element */
.values-banner::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.4); /* Dark overlay */
z-index: 1; /* Place the overlay behind the text */
}

/* Styling for titles */
.values-title, .values-customer-focus {
font-size: 3.8rem;
font-weight: 800;
text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
font-family: 'Nobel', sans-serif;
color: white;
z-index: 2; /* Ensure text is above the overlay */
}

.values-customer-focus {
color: #FFD700; /* Gold color for "CUSTOMERS FIRST" text */
margin-right: 10px;
}

@media (max-width: 1920px) {
.values-page {
  font-family: 'Nobel', sans-serif;
  color: #333;
  text-align: center;
  width: 100%;
  overflow: hidden;
}

/* Banner Section */
.values-banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 15px; /* Adjusted padding */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 20%;
  width: 100vw;
  height: 70vh; /* Adjusted height */
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
}

/* Dark overlay using ::before pseudo-element */
.values-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35); /* Adjusted overlay opacity */
  z-index: 1; /* Place the overlay behind the text */
}

/* Styling for titles */
.values-title, .values-customer-focus {
  font-size: 2.82rem; /* Adjusted font size */
  font-weight: 800;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6); /* Adjusted shadow */
  font-family: 'Nobel', sans-serif;
  color: white;
  z-index: 2; /* Ensure text is above the overlay */
}

.values-customer-focus {
  color: #FFD700; /* Gold color for "CUSTOMERS FIRST" text */
  margin-right: 8px; /* Adjusted margin */
}
}


/* For medium screens (max-width: 768px) */
@media (max-width: 768px) {
.values-banner {
  padding: 40px 15px;
  height: 60vh;
}

.values-title, .values-customer-focus {
  font-size: 1.5rem!important;
  margin-top: auto;
}
}

/* For small screens (max-width: 480px) */
@media (max-width: 480px) {
.values-banner {
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
  height: 50vh;
}

.values-title, .values-customer-focus {
  font-size: 2.2rem;
  padding: 0 15px;
}
}

/* Core Values Grid */
.core-values-grid {
display: flex;
justify-content: space-around;
padding: 30px;
background: linear-gradient(to right, #005b7f, #b7d3e3);
overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
}

/* Core Value Items */
.core-value {
flex: 1 1 200px; /* Flex items to take equal space */
max-width: 200px; /* Set a max width for consistency */
text-align: center;
color: #FFF;
border-radius: 5px;
}

.core-value img {
width: 150%;
height: 280px;
border-radius: 0px;
gap: 1px;
margin-left: -45px;
}

.core-value h3 {
font-size: 18px;
margin-top: 10px;
color: white;
font-family: 'Nobel', sans-serif;
}

.core-value p {
font-size: 17px;
color: #FFF;
font-family: 'Nobel', sans-serif;
}

@media (max-width: 1920px) {
.core-values-grid {
  display: flex;
  justify-content: space-around;
  padding: 15px; /* Adjusted padding */
  background: linear-gradient(to right, #005b7f, #b7d3e3);
  overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
}

/* Core Value Items */
.core-value {
  flex: 1 1 220px; /* Adjusted flex and min-width for layout */
  max-width: 200px; /* Adjusted max width */
  text-align: center;
  color: #FFF;
  border-radius: 5px;
}

.core-value img {
  width: 100%; /* Adjusted width */
  height: 230px; /* Adjusted height */
  border-radius: 0px;
  gap: 1px;
  margin-left: -20px; /* Adjusted margin */
}

.core-value h3 {
  font-size: 18px; /* Adjusted font size */
  margin-top: 8px; /* Adjusted margin */
  color: white;
  font-family: 'Nobel', sans-serif;
}

.core-value p {
  font-size: 16px; /* Adjusted font size */
  color: #FFF;
  font-family: 'Nobel', sans-serif;
}
}



/* For medium screens (max-width: 768px) */
@media (max-width: 768px) {
.core-values-grid {
  flex-direction: column;
  align-items: center;
  padding: 15px;
  gap: 20px;
}

.core-value {
  max-width: 90%;
}

.core-value img {
  width: 120%;
  height: 220px;
  margin-left: -20px;
}

.core-value h3 {
  font-size: 15px;
}

.core-value p {
  font-size: 14px;
}
}

/* For small screens (max-width: 480px) */
@media (max-width: 480px) {
.core-values-grid {
  padding: 10px;
  gap: 15px;
}

.core-value {
  max-width: 100%;
}

.core-value img {
  width: 100%;
  height: auto;
  margin-left: 0;
}

.core-value h3 {
  font-size: 14px;
}

.core-value p {
  font-size: 13px;
}
}


/* Container for centering the section */
.service-standards-container {
display: flex;
justify-content: center;
width: 100%; /* Ensures the container takes full width */
margin-top: 5px;

}

/* Service Standards Section */
.service-standards {
padding: 40px 20px;
background: linear-gradient(to right, #005b7f, #b7d3e3);
color: #FFF;
width: 100%;
align-items: center;

}

.standards-title {
font-size: 4rem;
text-align: left;
margin-bottom: 40px;
color: #FFF;
font-family: 'Nobel', sans-serif;
}

.standards-grid {
display: grid;
grid-template-columns: 1fr 1fr; /* Two-column layout */
gap: 20px;
}

.standard-item {
display: flex;
gap: 20px;
/* background-color: rgba(255, 255, 255, 0.1); */
 /* Slightly transparent background for contrast */
padding: 20px;
border-radius: 0px;
}

.standard-item img {
width: 330px;
height: auto;
border-radius: 5px;
object-fit: cover;
}

.standard-item h3 {
font-size: 40px;
color: black;
margin: 0;
font-family: 'Nobel', sans-serif;
}

.standard-item p {
font-size: 28px;
color: black;
font-family: 'Nobel', sans-serif;
}


@media (max-width: 1920px) {
.service-standards-container {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensures the container takes full width */
  margin-top: 10px; /* Adjusted margin for spacing */
}

/* Service Standards Section */
.service-standards {
  padding: 50px 30px; /* Adjusted padding for larger screens */
  background: linear-gradient(to right, #005b7f, #b7d3e3);
  color: #FFF;
  width: 100%;
  align-items: center;
}

.standards-title {
  font-size: 2.9rem; /* Increased font size for emphasis */
  text-align: left;
  margin-bottom: 20px; /* Adjusted margin for spacing */
  color: #FFF;
  font-family: 'Nobel', sans-serif;
}

.standards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two-column layout */
  gap: 10px; /* Increased gap for spacing */
}

.standard-item {
  display: flex;
  gap: 25px; /* Increased gap for better layout */
  padding: 30px; /* Adjusted padding for spacing */
  border-radius: 0px;
}

.standard-item img {
  width: 260px; /* Adjusted width for better visibility */
  height: 190px;
  border-radius: 5px;
  object-fit: cover;
}

.standard-item h3 {
  font-size: 30px; /* Adjusted font size */
  color: black;
  margin: 0;
  font-family: 'Nobel', sans-serif;
}

.standard-item p {
  font-size: 20px; /* Adjusted font size */
  color: black;
  font-family: 'Nobel', sans-serif;
}
}

/* For medium screens (max-width: 768px) */
@media (max-width: 768px) {
.service-standards {
  padding: 30px 10px;
}

.standards-title {
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 30px;
}

.standards-grid {
  grid-template-columns: 1fr;
  gap: 1px !important;
}

.standard-item img {
  width: 250px;
}

.standard-item h3 {
  font-size: 28px;
  text-align:center;
}

.standard-item p {
  font-size: 20px;
  text-align:center;
}
}

/* For small screens (max-width: 480px) */
@media (max-width: 480px) {
.service-standards {
  padding: 20px 5px;
}

.standards-title {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 20px;
}

.standard-item {
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.standard-item img {
  width: 100%;
  max-width: 200px;
}

.standard-item h3 {
  font-size: 24px;
}

.standard-item p {
  font-size: 18px;
  text-align: center;
}
}


/* General Styles for Gallery Page */
.gallery-page {
padding: 40px 20px;
background: linear-gradient(to right, #013220, #b8c9c5, #f0f4f3);
color: #FFF;
margin-top: 85px;
}

/* Gallery Title */
.gallery-title {
font-size: 3.4rem !important;
margin-top: 25px;
margin-bottom: 30px;
color: #FFF;
text-align: left;
font-family: 'Nobel', sans-serif;
}

/* Section Title */
.section-title {
font-size: 2.6rem;
margin-top: 20px;
color: darkslategray;
text-align: center;
font-family: 'Nobel', sans-serif;
}

/* Gallery Grid */
.gallery-grid {
display: grid;
grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens */
gap: 20px;
margin-top: 30px;
}

.gallery-grid img {
width: 100%;
height: 340px;
object-fit: cover;
border-radius: 0px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Gallery Grid */
.gallery-section {
margin-bottom: 0px;
margin-left:20px;
margin-right: 20px;
}

@media (max-width: 1920px) {
/* General Styles for Gallery Page */
.gallery-page {
  padding: 50px 30px; /* Increased padding for larger screens */
  background: linear-gradient(to right, #013220, #b8c9c5, #f0f4f3);
  color: #FFF;
  margin-top: 50px; /* Adjusted top margin */
}

/* Gallery Title */
.gallery-title {
  font-size: 2.4rem !important; /* Increased font size for emphasis */
  margin-top: 25px;
  margin-bottom: 30px; /* Adjusted bottom margin for spacing */
  color: #FFF;
  text-align: left;
  font-family: 'Nobel', sans-serif;
}

/* Section Title */
.section-title {
  font-size: 2rem; /* Increased font size */
  margin-top: 20px; /* Adjusted top margin */
  color: darkslategray;
  text-align: center;
  font-family: 'Nobel', sans-serif;
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Maintain 4 columns for large screens */
  gap: 20px; /* Increased gap for better spacing */
  margin-top: 30px; /* Adjusted top margin for spacing */
}

.gallery-grid img {
  width: 100%;
  height: 260px; /* Increased height for larger images */
  object-fit: cover;
  border-radius: 0px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow for more depth */
}

/* Gallery Section */
.gallery-section {
  margin-bottom: 20px; /* Adjusted bottom margin */
  margin-left: 30px; /* Increased left margin for spacing */
  margin-right: 30px; /* Increased right margin for spacing */
}
}



/* For medium screens (max-width: 768px) */
@media (max-width: 768px) {
body {
  overflow-x: hidden; /* Prevent any horizontal scrolling on the body */
}

.gallery-page {
  padding: 30px 15px;
  margin-top: 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.gallery-title {
  font-size: 2.6rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.section-title {
  font-size: 2.2rem;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%; /* Ensure grid fits within the container */
  box-sizing: border-box;
}

.gallery-grid img {
  height: 260px;
  width: 100%; /* Scale images to the grid item width */
  object-fit: cover;
}
}


/* For small screens (max-width: 480px) */
@media (max-width: 480px) {
.gallery-page {
  padding: 20px 10px;
}

.gallery-title {
  font-size: 2.2rem;
  margin-bottom: 1.25rem;
  text-align: center;
}

.section-title {
  font-size: 1.8rem;
}

.gallery-grid {
  grid-template-columns: 1fr; /* 1 column for small screens */
  gap: 8px;
}

.gallery-grid img {
  height: 200px;
}
}

/* Partners Section */
.partners-section {
display: flex;
padding: 100px 20px;
position: relative; /* Ensure the overlay covers the section */
margin-bottom: 0;
}

.partners-content p:last-child, .partners-content ul:last-child {
margin-bottom: 0; /* Remove the bottom margin of the last paragraph or list */
}


.partners-section::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(to right, rgba(204, 0, 0, 0.8), rgba(204, 0, 0, 0) 60%); /* Bright red overlay */
z-index: 1; /* Place the overlay behind the text */
}

/* Image Container with Gradient Overlay */
.partners-image-container {
position: relative;
width: 40%;
margin-left: 150px;
display: flex;
align-items: center;
justify-content: center;
margin-top: -230px;
z-index: 2; /* Ensure the image is above the overlay */
}

.partners-image-container img {
width: 100%;
height: auto;
}

/* Title Styling */
.partners-title {
position: absolute;
color: #FFF;
font-size: 48px;
font-weight: bold;
top: 50%;
left: -100px;
transform: translateY(-50%);
text-transform: uppercase;
font-family: 'Nobel', sans-serif;
z-index: 3; /* Ensure the title is above the image and overlay */
}

/* Content Section */
.partners-content {
width: 60%;
padding-left: 40px;
color: #333;
font-family: 'Nobel', sans-serif;
z-index: 3; /* Ensure content is on top of the overlay */
}
.partners-content p:last-child {
margin-bottom: 0; /* Remove the bottom margin of the last paragraph */
}

.partners-content h3 {
font-size: 24px;
color: #8b0000;
margin-top: 10px;
font-family: 'Nobel', sans-serif;
}

.partners-content p, .partners-content li {
font-size: 18px;
line-height: 1.5;
font-family: 'Nobel', sans-serif;
margin-top: 0;
}
.partners-content ul {
list-style-type: none;
padding-left: 0;
font-size: 18px;
line-height: 1.5;
font-family: 'Nobel', sans-serif;
margin-top: 0px;
}

@media (max-width: 1920px) {

/* Partners Section */
.partners-section {
display: flex;
padding: 100px 20px;
position: relative; /* Ensure the overlay covers the section */
margin-bottom: 0;
}

.partners-content p:last-child, .partners-content ul:last-child {
margin-bottom: 0; /* Remove the bottom margin of the last paragraph or list */
}


.partners-section::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(to right, rgba(204, 0, 0, 0.8), rgba(204, 0, 0, 0) 60%); /* Bright red overlay */
z-index: 1; /* Place the overlay behind the text */
}

/* Image Container with Gradient Overlay */
.partners-image-container {
position: relative;
width: 40%;
margin-left: 150px;
display: flex;
align-items: center;
justify-content: center;
margin-top: -230px;
z-index: 2; /* Ensure the image is above the overlay */
}

.partners-image-container img {
width: 100%;
height: auto;
}

/* Title Styling */
.partners-title {
position: absolute;
color: #FFF;
font-size: 48px;
font-weight: bold;
top: 50%;
left: -100px;
transform: translateY(-50%);
text-transform: uppercase;
font-family: 'Nobel', sans-serif;
z-index: 3; /* Ensure the title is above the image and overlay */
}

/* Content Section */
.partners-content {
width: 60%;
padding-left: 40px;
color: #333;
font-family: 'Nobel', sans-serif;
z-index: 3; /* Ensure content is on top of the overlay */
}
.partners-content p:last-child {
margin-bottom: 0; /* Remove the bottom margin of the last paragraph */
}

.partners-content h3 {
font-size: 24px;
color: #8b0000;
margin-top: 10px;
font-family: 'Nobel', sans-serif;
}

.partners-content p, .partners-content li {
font-size: 18px;
line-height: 1.5;
font-family: 'Nobel', sans-serif;
margin-top: 0;
}
.partners-content ul {
list-style-type: none;
padding-left: 0;
font-size: 18px;
line-height: 1.5;
font-family: 'Nobel', sans-serif;
margin-top: 0px;
}

}

/* Responsive Styles for Smaller Screens */
@media only screen and (max-width: 768px) {
.partners-section {
  flex-direction: column; /* Stack the image and content vertically */
  padding: 20px; /* Reduce padding for smaller screens */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.partners-image-container {
  width: 100%; /* Make the image container full width */
  margin: 0 auto 20px; /* Center the image container and add spacing below */
  text-align: center; /* Center-align the content inside */
  position: relative; /* Needed for title positioning */
}

.partners-image-container img {
  width: 100%; /* Ensure the image spans the container */
  height: auto; /* Maintain aspect ratio */
}

.partners-title {
  position: absolute; /* Position it relative to the image container */
  top: 50%; /* Vertically center */
  left: 50%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Adjust position to be perfectly centered */
  text-align: center; /* Center the text */
  font-size: 24px; /* Adjust font size for smaller screens */
  font-weight: bold; /* Ensure the title is bold */
  color: #FFF; /* Keep the white color for contrast */
  z-index: 2; /* Ensure it appears above the image */
}

.partners-content {
  width: 100%; /* Make the content container full width */
  padding: 10px; /* Add padding for spacing */
}

.partners-content h3 {
  font-size: 20px; /* Adjust heading font size */
  margin-top: 15px; /* Add spacing above headings */
}

.partners-content p, 
.partners-content li {
  font-size: 16px; /* Adjust paragraph and list font size */
  line-height: 1.4; /* Adjust line height for readability */
}

.partners-content ul {
  padding-left: 20px; /* Add padding for list indentation */
  margin-top: 10px; /* Add spacing above lists */
}
}


/* For Tablets (768px and below) */

/* For Mobile Devices (480px and below) */



/* Base styles (large screens) */
.contact-container {
background: linear-gradient(120deg, #f0f4f8, #d9e2ec);
padding: 20px;
border-radius: 10px;
}

.communication-image {
border-radius: 8px;
margin-bottom: 30px;
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-heading {
font-size: 2.5rem;
font-weight: bold;
margin-bottom: 30px;
color: #333;
font-family: 'Nobel', sans-serif;
}

.enquiry-options {
display: flex;
justify-content: center;
margin-bottom: 40px;
}

.enquiry-option {
padding: 20px;
border: 1px solid #ddd;
border-radius: 8px;
text-align: center;
cursor: pointer;
transition: box-shadow 0.3s ease;
background-color: #f8f8f8;
margin: 0 15px;
}

.enquiry-option:hover {
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.enquiry-option h2 {
font-size: 1.5rem;
margin-bottom: 15px;
}

.enquiry-option p {
font-size: 1rem;
margin-bottom: 10px;
}

.enquiry-form {
background-color: #fff;
padding: 30px;
border: 1px solid #ddd;
border-radius: 8px;
max-width: 600px;
margin: 0 auto;
}

.enquiry-form h2 {
font-size: 2rem;
margin-bottom: 20px;
}

.inline-radio-group {
display: flex;
justify-content: flex-start;
margin-bottom: 15px;
}

.inline-radio-group .form-check {
margin-right: 20px;
}
@media (max-width: 768px) {
.enquiry-options {
  flex-direction: column; /* Stack items vertically on smaller screens */
  align-items: center; /* Center items */
}

.enquiry-option {
  flex: none; /* Remove flex-grow and ensure consistent width */
  width: 90%; /* Adjust to occupy most of the screen width */
  margin: 10px 0; /* Add vertical spacing */
}
}
/* Default styles for larger screens remain unchanged */

/* Styles for smaller screens */
@media (max-width: 768px) {
.form-group div {
  margin-bottom: 15px;
}

.form-group div span {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.form-group div label {
  display: block;
  margin-bottom: 5px;
}

.form-group div input {
  margin-right: 10px;
}
}



/****************************** Animated-Content *******************************/
.animated-content {
display: flex;
align-items: center;
justify-content: space-between;
opacity: 0;
transform: translateY(20px);
transition: opacity 5s, transform 5s;
}

.animated-content.show{
opacity:1;
transform: translateY(0);
}

/************************** Animation ************************************/
@keyframes bgAnimation1 {
0% {
  background-image: "../assets/Coffee-man.jpg";
}

50%{
  background-image: '../assets/Gulfport.jpeg';
}

100%{
  background-image: "../assets/Coffee-man.jpg";
}
}

@keyframes bgAnimation2 {
0%{
  background-image: '../assets/Gulfport.jpeg' ;
}

50%{
  background-image: "../assets/Coffee-man.jpg";
}

100%{
  background-image: '../assets/Gulfport.jpeg';
}
}

.scrolling-background{
width: 100%;
height: 100vh;
background-size: cover;
background-repeat: no-repeat;
animation: bgAnimation1 10s linear infinite;
}

.container-box{
display:flex;
width:100%;
}

.box{
display:flex;
flex:1;
margin-right: 10px;
border: 1px solid #ccc;
border-radius: 5px;
box-shadow: 0 0 10px rgb(0 0 0 / 10%);
background-color: smoky white;
}

.text{
flex: 1;
padding: 10px;
color: black;
align-self: flex-end;
}

.image imagehistory{
flex:1;
padding: 10px;
max-width: 100%;
height: auto;
}

/******************* Business *******************************/
.business {
max-width: 600px;
margin: 0 auto;
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
background-color: #fff;
}

.enquiry-form{
margin: 0 auto;
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
background-color: white;
width: 100%;
}

.form-inline{
width:100%;
padding: 10px;
}

.form-group {
margin-bottom: 15px;
}

label {
display: block;
margin-bottom: 5px;
font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
font-size: 16px;
}

.error {
color: #f00;
font-size: 14px;
margin-top: 5px;
}

.ContactUsImage{
display: inline !important;
width: 100%;
}
.ContactUsImageContaineri {
width: 100%; /* Make container take full width */
height: 450px; /* Set height for the container */
position: relative;
overflow: hidden;
margin-top: -80px;
}

.ContactUsImagei {
width: 100%;
height: 100%;
object-fit: cover; /* Ensures the image covers the container */
object-position: top;
clip-path: inset(100px 0 0 0);
}

.text-overlayi {
position: absolute;
top: 50%; /* Increase the percentage to move the text down */
left: 50%;
transform: translate(-50%, -50%);
text-align: center;
color: #fff;
font-family: 'Nobel', sans-serif;
}

/* Contact image section */
.communication-image {
border-radius: 8px;
margin-bottom: 30px;
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Heading Styling */
.contact-heading {
font-size: 2.5rem;
font-weight: bold;
margin-bottom: 30px;
color: #333;
font-family: 'Nobel', sans-serif;
}

@media (max-width: 1920px){
.contact-container {
  background: linear-gradient(120deg, #f0f4f8, #d9e2ec);
  padding: 20px;
  border-radius: 10px;
}

.communication-image {
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
  font-family: 'Nobel', sans-serif;
}

.enquiry-options {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.enquiry-option {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  background-color: #f8f8f8;
  margin: 0 15px;
}

.enquiry-option:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.enquiry-option h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.enquiry-option p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.enquiry-form {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

.enquiry-form h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.inline-radio-group {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.inline-radio-group .form-check {
  margin-right: 20px;
}

@media (max-width: 768px) {
  .ContactUsImageContaineri {
    width: 100%!important;
    height: 500px!important; /* Reduce the height for smaller screens */
    position: relative;
    overflow: hidden;
    margin-top: -50px!important; /* Adjust the margin as needed */
  }

  .ContactUsImagei {
    width: 100%!important;
    height: 100%!important;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: top!important; /* Focus on the top of the image */
    clip-path: inset(50px 0 0 0); /* Adjust the clipped area for smaller screens */
  }

  .text-overlayi {
    position: absolute;
    top: 40%; /* Adjust position for smaller screens */
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-family: 'Nobel', sans-serif;
    font-size: 1.8rem; /* Reduce font size */
  }

  /* Contact image section */
  .communication-image {
    border-radius: 8px;
    margin-bottom: 20px; /* Adjust margin for smaller screens */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly lighter shadow */
  }

  /* Heading Styling */
  .contact-heading {
    font-size: 2rem; /* Reduce font size */
    font-weight: bold;
    margin-bottom: 20px; /* Adjust spacing below heading */
    color: #333;
    font-family: 'Nobel', sans-serif;
    text-align: center; /* Center-align text for smaller screens */
  }
}


/****************************** Animated-Content *******************************/
.animated-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 5s, transform 5s;
}

.animated-content.show{
  opacity:1;
  transform: translateY(0);
}

/************************** Animation ************************************/
@keyframes bgAnimation1 {
  0% {
    background-image: "../assets/Coffee-man.jpg";
  }

  50%{
    background-image: '../assets/Gulfport.jpeg';
  }

  100%{
    background-image: "../assets/Coffee-man.jpg";
  }
}

@keyframes bgAnimation2 {
  0%{
    background-image: '../assets/Gulfport.jpeg' ;
  }

  50%{
    background-image: "../assets/Coffee-man.jpg";
  }

  100%{
    background-image: '../assets/Gulfport.jpeg';
  }
}

.scrolling-background{
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  animation: bgAnimation1 10s linear infinite;
}

.container-box{
  display:flex;
  width:100%;
}

.box{
  display:flex;
  flex:1;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background-color: smoky white;
}

.text{
  flex: 1;
  padding: 10px;
  color: black;
  align-self: flex-end;
}

.image imagehistory{
  flex:1;
  padding: 10px;
  max-width: 100%;
  height: auto;
}

/******************* Business *******************************/
.business {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  background-color: #fff;
}

.enquiry-form{
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  width: 100%;
}

.form-inline{
  width:100%;
  padding: 10px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.error {
  color: #f00;
  font-size: 14px;
  margin-top: 5px;
}

.ContactUsImage{
  display: inline !important;
  width: 100%;
}
.ContactUsImageContaineri {
  width: 100%; /* Make container take full width */
  height: 450px; /* Set height for the container */
  position: relative;
  overflow: hidden;
  margin-top: -80px;
}

.ContactUsImagei {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  object-position: top;
  clip-path: inset(100px 0 0 0);
}

.text-overlayi {
  position: absolute;
  top: 50%; /* Increase the percentage to move the text down */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-family: 'Nobel', sans-serif;
}

/* Contact image section */
.communication-image {
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Heading Styling */
.contact-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
  font-family: 'Nobel', sans-serif;
}

}
/********************** Copyright - Footer *******************/
.footer h5 {
font-size: 1.86rem;
font-weight: bold;
}

.footer p {
font-size: 1.2rem;
}

.footer-links {
font-size: 1.2rem;
color: white;
text-align: center;
margin-top: 0.625rem; /* 10px */
}

.copyrightfooter {
text-align: right;
color: darkgrey;
font-size: small;
}

/* Footer Styling */
.footer {
padding: 1.5%;
background-color: #035555;
height: 23.125rem; /* 370px */
}

.team-icon li a:hover {
color: #dedede;
transition: all 0.25s ease-in-out;
}

/*********************************** Dropdown ***************************************/
ul.team-icon {
list-style: none;
padding: 0;
display: flex;
justify-content: center;
gap: 0.625rem; /* 10px */
margin-top: 1.25rem; /* 20px */
}

ul.team-icon li {
display: inline-block;
padding: 0.5rem; /* 8px */
border-radius: 50%;
color: white;
font-size: 1.75rem; /* 28px */
width: 2.5rem; /* 40px */
height: 2.5rem; /* 40px */
display: flex;
align-items: center;
justify-content: center;
}

ul.team-icon li.twitter {
background-color: #4099ff;
}

ul.team-icon li.instagram {
background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

ul.team-icon li.facebook {
background-color: #3b5998;
}

ul.team-icon li.linkedIn {
background-color: #0077B5;
}

ul.team-icon li.Youtube {
background-color: red;
}

/* Mobile version for footer */
@media (max-width: 768px) {
.footer h5 {
  font-size: 1.4rem; /* Adjusted for mobile */
  font-weight: bold;
  text-align: center;
}

.footer p {
  font-size: 0.975rem; /* Adjusted for mobile */
  text-align: center;
  margin: 0 auto;
}

.footer-links {
  font-size: 1rem; /* Adjusted size for mobile */
  text-align: center;
  margin-top: 8px;
}

.copyrightfooter p {
  font-size: 0.75rem !important; /* Adjust as needed */
  text-align: center;
  color: darkgrey; /* Make sure to set the color if needed */
}

.footer {
  padding: 3%; /* Increase padding for better touch accessibility */
  height: auto; /* Allow flexible height for smaller screens */
}

ul.team-icon {
  gap: 8px; /* Reduced gap between icons for mobile */
  margin-top: 15px; /* Adjusted margin */
}

ul.team-icon li {
  font-size: 20px; /* Smaller icon size */
  width: 35px; /* Smaller width */
  height: 35px; /* Smaller height */
}
}

/* Styles for screens with a max-width of 1920px */
@media (max-width: 1920px) {
.footer h5 {
  margin-top:5px;
  font-size: 1.35rem; /* Slightly reduced font size */
}

.footer p {
  font-size: 1.1rem; /* Adjusted for better readability */
}

.footer-links {
  font-size: 1.1rem; /* Reduced font size */
  margin-top: 0.5rem; /* Adjusted margin */
}

.copyrightfooter {
  font-size: 0.9rem; /* Slightly smaller font size */
}

.footer {
  padding: 1.2%; /* Reduced padding */
  height: 18.8rem; /* Adjusted height */
}

ul.team-icon {
  gap: 0.5rem; /* Reduced gap */
  margin-top: 1rem; /* Adjusted margin */
}

ul.team-icon li {
  font-size: 1.6rem; /* Slightly smaller icon size */
  width: 2.25rem; /* Reduced width */
  height: 2.25rem; /* Reduced height */
}
}

/* Styles for screens with a max-width of 480px */
/* Footer styles for mobile screens up to 480px */
@media (max-width: 480px) {
.footer {
  padding: 2%; /* Consistent padding for better spacing */
  height: auto; /* Ensures the footer adapts to content */
  background-color: #003d3c; /* Ensures consistent background */
  color: white; /* Readable text color */
  text-align: center; /* Centers all content */
}

.footer h5 {
  font-size: 1.4rem; /* Reduced heading size for better fit */
  margin-bottom: 0.5rem; /* Space below the heading */
}

.footer p {
  font-size: 0.9rem; /* Smaller font size for text */
  margin: 0.5rem 0; /* Consistent spacing for text blocks */
}

.footer-links {
  font-size: 0.9rem; /* Reduced link size */
  margin: 0.3rem 0; /* Top and bottom spacing */
  display: flex;
  flex-wrap: wrap; /* Wrap links for narrow screens */
  justify-content: center; /* Center-align links */
}

.footer-links a {
  margin: 0 0.3rem; /* Spacing between links */
  text-decoration: none;
  color: #ffffff; /* Link color */
}

.footer-links a:hover {
  color: #9bf5e6; /* Hover effect for links */
}

.copyrightfooter {
  text-align: center; /* Center-align text */
  font-size: 0.8rem; /* Smaller font size for copyright */
  margin-top: 1rem; /* Space above the copyright section */
}

ul.team-icon {
  gap: 0.3rem; /* Reduced gap for icons */
  margin: 0.8rem 0; /* Top and bottom margin */
  display: flex;
  justify-content: center; /* Center-align icons */
}

ul.team-icon li {
  font-size: 1.2rem; /* Reduced icon size */
  width: 2rem; /* Adjusted icon container size */
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* Icon background color */
  border-radius: 50%; /* Circular icons */
}

ul.team-icon li a {
  color: #003d3c; /* Icon color */
  font-size: 1.2rem; /* Icon font size */
}

ul.team-icon li:hover {
  background-color: #9bf5e6; /* Background color on hover */
  transform: scale(1.1); /* Slight zoom effect */
  transition: all 0.3s ease-in-out;
}
}



/******************-Divider***********************/
/* Main section container */
.locations-brands-section {
  padding: 2.5rem; /* 40px */
  background: linear-gradient(
      to right,
      rgba(255, 215, 0, 0.8),
      rgba(255, 255, 224, 0.3) 70%,
      transparent 100%
  );
  z-index: 1;
}

/* Locations Section Styling */
.locations-section {
  margin-bottom: 2.5rem; /* 40px */
}

.locations-section h2 {
  font-size: 3rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.25rem; /* 20px */
  font-family: 'Nobel', sans-serif;
}

.locations-gallery {
  display: flex;
  gap: 1.25rem; /* 20px */
  justify-content: center;
  flex-wrap: wrap;
}

.locations-gallery img {
  width: 15.625rem; /* 250px */
  height: auto;
  border: 0.125rem solid #d32f2f; /* 2px */
  border-radius: 0.3125rem; /* 5px */
}

.custom-divider {
height: 0.1875rem; /* 3px */
background-color: #b1001f;
margin: 1.25rem 0; /* 20px */
width: 100%;
margin-left: auto;
margin-right: auto;
margin-top: -28.125rem; /* -450px */
}

.locationimage-carousel-container {
max-width: 100%;
margin: 0 auto;
margin-bottom: -6.25rem; /* -100px */
}

.locationcarousel-image-group {
display: flex;
justify-content: center;
padding: 0;
gap: 0.3125rem; /* 5px */
margin-bottom: -0.625rem; /* -10px */
}

.locationcarousel-card {
position: relative;
flex: 1;
margin: 0;
text-align: center;
}

.locationcarousel-caption {
position: absolute;
bottom: 0.625rem; /* 10px */
left: 50%;
transform: translateX(-50%);
font-size: 0.9rem;
color: #fff;
background-color: rgba(0, 0, 0, 0.6);
padding: 0.3125rem 0.625rem; /* 5px 10px */
border-radius: 0.3125rem; /* 5px */
font-family: 'Nobel', sans-serif;
font-weight: bold;
width: 80%;
text-align: center;
}

.locationcarousel-image {
width: 100%;
height: auto;
object-fit: cover;
}

.locationimage-carousel-container {
max-width: 100%;
margin: 0 auto;
margin-bottom: -6.25rem; /* -100px */
position: relative;
}

.custom-carousel-indicators {
position: absolute;
bottom: 31.25rem; /* 500px */
left: 0;
right: 0;
display: flex;
justify-content: center;
gap: 0.3125rem; /* 5px */
list-style: none;
padding: 0;
margin: 0;
z-index: 10;
margin-bottom: 0.625rem; /* 10px */
}

.custom-carousel-indicators li {
width: 1.25rem; /* 20px */
height: 0.25rem; /* 4px */
background-color: rgba(0, 0, 0, 0.8);
border-radius: 0.125rem; /* 2px */
margin: 0.25rem; /* 4px */
transition: background-color 0.3s ease;
}

.custom-carousel-indicators .active {
background-color: red;
}

@media (max-width: 1920px) {
.locations-brands-section {
  padding: 3rem; /* Increase padding for more space */
  background: linear-gradient(
    to right,
    rgba(255, 215, 0, 0.85),
    rgba(255, 255, 224, 0.4) 70%,
    transparent 100%
  );
}

.locations-section h2 {
  font-size: 2.2rem; /* Larger font size for headings */
  margin-bottom: 1.5rem; /* Adjust margin */
  font-family: 'Nobel', sans-serif;
}

.locations-gallery {
  gap: 1.5rem; /* Increase gap between images */
}

.locations-gallery img {
  width: 18rem; /* Increase image width for a larger view */
  border: 0.1875rem solid #d32f2f; /* Adjust border thickness */
  border-radius: 0.375rem; /* Slightly larger border radius */
}

.custom-divider {
  height: 0.25rem; /* Thicker divider */
  margin-top: -30rem; /* Adjust margin for better alignment */
}

.locationimage-carousel-container {
  margin-bottom: 10rem; /* Adjust negative margin */
}

.locationcarousel-image-group {
  gap: 0.5rem; /* Adjust spacing between images */
}

.locationcarousel-caption {
  font-size: 1rem; /* Larger font for better readability */
  padding: 0.5rem 1rem; /* Increase padding */
}

.custom-carousel-indicators {
  bottom: 23rem; /* Adjust position of indicators */
}

.custom-carousel-indicators li {
  width: 1.5rem; /* Increase size of indicators */
  height: 0.3rem; /* Adjust height */
}
}

/* Media query for 1366px width screens (15-inch HP laptop) */
@media (max-width: 1366px) {
.locationimage-carousel-container {
  max-width: 100%; /* Ensure the container spans the full width */
  margin-bottom: -4rem; /* Reduce the negative margin */
}

.locationcarousel-image-group {
  gap: 0.2rem; /* Reduce the gap between images */
  margin-bottom: 0; /* Set margin-bottom to 0 to avoid overflow */
}

.locationcarousel-card {
  flex: 1;
  margin: 0;
  text-align: center;
}

.locationcarousel-caption {
  font-size: 0.8rem; /* Adjust font size for captions */
  padding: 0.2rem 0.5rem; /* Adjust padding */
}

.custom-carousel-indicators {
  bottom: 25rem; /* Lower the indicators to fit within the screen */
}

.custom-carousel-indicators li {
  width: 1rem; /* Reduce the size of the indicators */
  height: 0.2rem; /* Adjust height */
}
}

@media (max-width: 768px) {
.locationimage-carousel-container {
  max-width: 100%;
  margin-bottom: -50px; /* Adjusted for mobile view */
}

.locationcarousel-image-group {
  flex-direction: column; /* Stack images vertically for mobile */
  padding: 0;
  gap: 2px; /* Reduced gap for mobile */
  margin-bottom: -5px;
}

.locationcarousel-card {
  position: relative;
  flex: 1;
  margin: 0;
  text-align: center;
}

.locationcarousel-image {
  width: 100%;
  height: 215px; /* Reduced height for mobile */
  object-fit: cover;
}

.locationcarousel-caption {
  bottom: 5px; /* Move caption closer to the bottom */
  font-size: 0.8rem; /* Smaller font size for mobile readability */
  padding: 4px 8px; /* Reduced padding */
  width: 90%; /* Increased width for better fit on smaller screens */
}

.custom-carousel-indicators {
  bottom: -30px; /* Adjusted to position indicators near images */
  gap: 3px; /* Smaller gap for indicators on mobile */
}

.custom-carousel-indicators li {
  width: 15px;
  height: 3px;
}
.locations-section h2 {
  font-size: 1.5em; /* Reduced font size for mobile */
  margin-bottom: 15px; /* Adjusted margin for mobile */
  color: #333;
  font-weight: bold;
  font-family: 'Nobel', sans-serif;
}
.custom-divider {
  height: 2px; /* Thickness of the line */
  background-color: #b1001f; /* Red color for the line */
  margin: 20px 0; /* Space above and below */
  width: 100%; /* Adjust width if needed */
  margin-left: auto;
  margin-right: auto;
  margin-top: -350px;
}
}
/* Media Query for Small Mobile Devices (Up to 480px) */
@media (max-width: 480px) {
.locationimage-carousel-container {
  max-width: 100%; /* You can increase this if needed */
  width: 100%; /* Increased width to make the container larger */
  margin-bottom: -25px !important; /* Further reduced for small mobile screens */
}

.locationcarousel-image-group {
  flex-direction: column; /* Stack images vertically */
  padding: 0;
  gap: 1px; /* Minimized gap for small screens */
  margin-bottom: -3px;
}

.locationcarousel-card {
  position: relative;
  flex: 1;
  margin: 0;
  text-align: center;
}

.locationcarousel-image {
  width: 100%;
  height: 160px; /* Increased height for a larger view */
  object-fit: cover;
}

.locationcarousel-caption {
  bottom: 3px; /* Bring caption even closer to the image bottom */
  font-size: 0.7rem; /* Smaller font size for compact readability */
  padding: 3px 6px; /* Minimized padding */
  width: 95%; /* Increased width for a better fit */
}

.custom-carousel-indicators {
  bottom: -35px; /* Adjusted position closer to images */
  gap: 2px; /* Smaller gap for indicators on small screens */
}

.custom-carousel-indicators li {
  width: 12px; /* Smaller indicator width */
  height: 2px; /* Smaller indicator height */
}

.locations-section h2 {
  font-size: 1.2em; /* Further reduced font size */
  margin-bottom: 5px; /* Adjusted margin for compact screens */
  color: #333;
  font-weight: bold;
  font-family: 'Nobel', sans-serif;
}

.custom-divider {
  height: 1.5px; /* Thinner line for small screens */
  background-color: #b1001f; /* Red color */
  margin: 15px 0; /* Reduced space above and below */
  width: 100%; /* Full width */
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px; /* Further adjusted for smaller screens */
}
}

/* Media Query for Laptop Screens (1024px to 1440px) */

/* Media Query for Laptop Screens (1024px to 1440px) */
@media (min-width: 1024px) and (max-width: 1440px) {
.locationimage-carousel-container {
  max-width: 95%; /* Adjusted to slightly reduce the width */
  margin-bottom: -6rem; /* Reduced negative margin */
}

.locationcarousel-image-group {
  gap: 0.5rem; /* Adjust spacing between images */
}

.locationcarousel-caption {
  font-size: 0.85rem; /* Adjusted for better readability */
  padding: 0.3rem 0.7rem; /* Adjusted padding */
}

.custom-carousel-indicators {
  bottom: 28rem; /* Adjust position of indicators */
}

.locations-section h2 {
  font-size: 2.2rem; /* Adjusted font size for headings */
  margin-bottom: 0.8rem; /* Adjusted margin */
}

.custom-divider {
  height: 1.5px; /* Adjusted thickness */
  margin-top: -30rem; /* Adjusted negative margin for alignment */
}

.custom-carousel-indicators li {
  width: 1rem; /* Adjusted size of indicators */
  height: 0.2rem; /* Adjusted height */
}
}

/* ---------- iPad Regular (9.7"/10.2") Portrait ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 4vw;
  }

  .locations-gallery {
    gap: 2vw;
  }

  .locations-gallery img {
    width: 30%!important;
    height: 25.75rem!important; /* Increased height for iPad Portrait */
  }

  .locationcarousel-caption {
    font-size: 2vw;
  }
}

/* ---------- iPad Regular (9.7"/10.2") Landscape ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 3.5vw;
  }

  .locations-gallery {
    gap: 1.5vw;
  }

  .locations-gallery img {
    width: 20%!important;
    height: 25.75rem!important; /* Increased height for iPad Landscape */
  }

  .locationcarousel-caption {
    font-size: 1.8vw;
  }
}

/* ---------- iPad Air (820px to 1180px) Portrait ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 3.8vw;
  }

  .locations-gallery {
    gap: 2vw;
  }

  .locations-gallery img {
    width: 30%!important;
    height: 25.75rem!important; /* Increased height for iPad Air Portrait */
  }

  .locationcarousel-caption {
    font-size: 2vw;
  }
}

/* ---------- iPad Air (820px to 1180px) Landscape ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 3.5vw;
  }

  .locations-gallery {
    gap: 1.5vw;
  }

  .locations-gallery img {
    width: 20%!important;
    height: 25.75rem!important; /* Increased height for iPad Air Landscape */
  }

  .locationcarousel-caption {
    font-size: 1.8vw;
  }
}

/* ---------- iPad Pro 10.5" Portrait ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: portrait) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 3.8vw;
  }

  .locations-gallery {
    gap: 2vw;
  }

  .locations-gallery img {
    width: 30%!important;
    height: 25.75rem!important; /* Increased height for iPad Pro 10.5" Portrait */
  }

  .locationcarousel-caption {
    font-size: 2vw;
  }
}

/* ---------- iPad Pro 10.5" Landscape ---------- */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: landscape) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 3.5vw;
  }

  .locations-gallery {
    gap: 1.5vw;
  }

  .locations-gallery img {
    width: 20%!important;
    height: 25.75rem!important; /* Increased height for iPad Pro 10.5" Landscape */
  }

  .locationcarousel-caption {
    font-size: 1.8vw;
  }
}

/* ---------- iPad Pro 12.9" Portrait ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 3.5vw;
  }

  .locations-gallery {
    gap: 2vw;
  }

  .locations-gallery img {
    width: 30%!important;
    height: 25.75rem!important; /* Increased height for iPad Pro 12.9" Portrait */
  }

  .locationcarousel-caption {
    font-size: 2vw;
  }
}

/* ---------- iPad Pro 12.9" Landscape ---------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .locations-brands-section {
    padding: 4vw;
  }

  .locations-section h2 {
    font-size: 3.5vw;
  }

  .locations-gallery {
    gap: 1.5vw;
  }

  .locations-gallery img {
    width: 20%!important;
    height: 25.75rem!important; /* Increased height for iPad Pro 12.9" Landscape */
  }

  .locationcarousel-caption {
    font-size: 1.8vw;
  }
}


/**********************Careers***************************/
.careers-img {
width: 100%;
height: 80vh;
object-fit: cover;
}

.WhyJoinFaber{
font-family: 'Times New Roman', Times, serif;
color: navy;
margin-top: 3%;
margin-bottom:3%;
font-size: 40px;
text-align: center;
}

.CareersImage{
width: 100%;
height: auto;

}

.underline{
text-decoration: underline;
}

.serviceStandards span, .whatWeDo span{
text-decoration: underline;
}

.valuesBox {
position: absolute;
bottom: 0;
left: 0;
width: 30%;
background-color: blue; /* Adjust the color and transparency */
color: #fff; /* Text color inside the box */
padding: 10px;
transform: translateY(50%);
}

.why-join-section {
text-align: center; /* Center-align everything */
}

.messageContainer {
padding: 5px;
margin: 5px 0; /* Space above and below */
}

.messageHeading {
font-size: 1.5rem; /* Adjusted font size */
color: #CA3F00; /* Your accent color */
font-weight: bold; /* Semi-bold text */
margin: 0; /* Remove default margins */
}

.whyJoinFaber{
font-family: 'Times New Roman', Times, serif;
margin-left: 10px auto;
color: navy;
text-align: left;
font-size: 40px;
max-width: 600px;
position: relative;
}

.Events{
  font-family: 'Times New Roman', Times, serif;
color: navy;
text-align: center;
font-size: 60px;

}
.OurBenefits{
  font-family: 'Times New Roman', Times, serif;
  margin-left: 100px auto;
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
  background-color: white;
}

.PhotoGallery{
  font-family: 'Times New Roman', Times, serif;
  margin-left: 100px auto;
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
  background-color: white;
}

@media(max-width: 1920px){
  .PhotoGallery{
    font-family: 'Times New Roman', Times, serif;
    margin-left: 100px auto;
    text-align: center;
    font-size: 40px;
    margin-bottom: 30px;
    background-color: white;
  }
}
.containercareers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
}

.boxcareers {
  flex: 1 1 30%; /* 30% width with flexible sizing */
  margin: 10px;
  padding: 20px;
}

/* New content container for image and text */
.content-careers {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align all child elements to the left */
  
}

.gallery-image {
  width: 100%;
  max-width: 300px; /* Set maximum width for the image */
  height: auto;
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 15px;
}

.boxcareers h2 {
  margin-top: 10px;
  font-family: 'Serif';
  text-align: left;
}

.boxcareers p {
  margin-top: 10px;
  color: #666362;
  text-align: left;
}

@media(max-width: 1920px){
  .containercareers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
  }
  
  .boxcareers {
    flex: 1 1 30%; /* 30% width with flexible sizing */
    margin: 10px;
    padding: 20px;
  }
  
  /* New content container for image and text */
  .content-careers {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align all child elements to the left */
    
  }
  
  .gallery-image {
    width: 100%;
    max-width: 300px; /* Set maximum width for the image */
    height: auto;
    max-height: 200px;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .boxcareers h2 {
    margin-top: 10px;
    font-family: 'Serif';
    text-align: left;
  }
  
  .boxcareers p {
    margin-top: 10px;
    color: #666362;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .containercareers {
    flex-direction: column; /* Stack all the boxes vertically */
    align-items: center; /* Center the stacked boxes */
  }

  .boxcareers {
    flex: none; /* Remove flex-grow */
    width: 90%; /* Full-width for smaller screens */
    margin: 15px 0; /* Add vertical spacing */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .content-careers {
    align-items: center; /* Center align content */
    text-align: center; /* Center align text for better readability */
  }

  .gallery-image {
    max-width: 100%; /* Allow images to take full width */
    max-height: 250px; /* Slightly increase height limit for small screens */
    object-fit: contain; /* Ensure the entire image fits */
  }

  .boxcareers h2 {
    font-size: 1.4rem; /* Adjust font size for smaller screens */
    margin-top: 10px;
  }

  .boxcareers p {
    font-size: 1rem; /* Adjust paragraph font size */
    text-align: center; /* Center text for readability */
    margin-top: 10px;
  }
}
.section-white {
  margin-top: 20px;
  padding: 70px 0;

}

.happyimage img-fluid{
  width: 100%;
  height: 30vh;
}

.team-item{
  background: #f4f5f6;
  text-align:center;
  margin:20px 0;
  padding:50px 20px 40px;
  border-radius: 8px;
  height: 90%;
  transition: all 0.25s ease-in-out;
}

.team-item:hover{
  background: #3956de;
  color: white;
}

.team-item h3{
  margin:20px 0 1px!important;
  color:#454545;
  font-size:18px;
  font-weight: 700;
  text-transform:uppercase;
  transition: all .25s ease-in-out;
}

.section-subtitle {
  white-space: pre-line;
 }

.team-item:hover h3{
  color:#ddd;
}

.team-info {
  display: block;
  margin-bottom:0;
}

.team-info p{
  color:#acacac;
  font-style:italic;
}

.team-info::after {
    background: #008aff;
    background: linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    background: linear-gradient(bottom right, #6B02FF, #985BEF);
    background: linear-gradient(bottom right, #008aff, #1ad2fd);
    background: linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 50px;
    height: 3px; 
}

.team-img{
  max-width: 140px;
  padding: 6px;
    background-color: #dedede;
  border-radius: 50%;
  cursor: pointer;
}



.team-icon li a:hover{
  color:#dedede;
  transition: all 0.25s ease-in-out;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
.section-white {
  padding: 40px 20px; /* Reduce padding for smaller screens */
}

.team-item {
  padding: 20px 15px; /* Adjust padding for smaller cards */
  margin: 15px 0; /* Reduce margin for smaller screens */
  height: auto; /* Allow height to adjust dynamically */
}

.team-item h3 {
  font-size: 16px; /* Reduce font size for headings */
  margin: 15px 0 5px; /* Adjust margin for spacing */
}

.team-info p {
  font-size: 14px; /* Adjust font size for text */
}

.team-img {
  max-width: 90px; /* Reduce image size for smaller screens */
  max-height: 90px;
  padding: 2px; /* Adjust padding */
}

.container {
  padding: 0 15px; /* Add padding to container for smaller screens */
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center cards */
}

.col-sm-6 {
  flex: 1 1 100%; /* Make each column take full width */
  max-width: 100%; /* Prevent overflow */
  margin-bottom: 20px; /* Add spacing between rows */
}

.col-md-4 {
  flex: 1 1 100%; /* Ensure full width for smaller devices */
  max-width: 100%; /* Prevent overflow */
}
}

@media(max-width: 1920px){
  .OurBenefits{
    font-family: 'Times New Roman', Times, serif;
    margin-left: 100px auto;
    text-align: center;
    font-size: 40px;
    margin-bottom: 30px;
    background-color: white;
  }
  
  .section-white {
    margin-top: 20px;
    padding: 70px 0;

  }
  
  .happyimage img-fluid{
    width: 100%;
    height: 30vh;
  }
  
  .team-item{
    background: #f4f5f6;
    text-align:center;
    margin:20px 0;
    padding:50px 20px 40px;
    border-radius: 8px;
    height: 90%;
    transition: all 0.25s ease-in-out;
  }
  
  .team-item:hover{
    background: #3956de;
    color: white;
  }
  
  .team-item h3{
    margin:20px 0 1px!important;
    color:#454545;
    font-size:18px;
    font-weight: 700;
    text-transform:uppercase;
    transition: all .25s ease-in-out;
  }
  
  .section-subtitle {
    white-space: pre-line;
   }
  
  .team-item:hover h3{
    color:#ddd;
  }
  
  .team-info {
    display: block;
    margin-bottom:0;
  }
  
  .team-info p{
    color:#acacac;
    font-style:italic;
  }
  
  .team-info::after {
      background: #008aff;
      background: linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
      background: linear-gradient(bottom right, #6B02FF, #985BEF);
      background: linear-gradient(bottom right, #008aff, #1ad2fd);
      background: linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 50px;
      height: 3px; 
  }
  .team-img{
    max-width: 140px;
    padding: 6px;
      background-color: #dedede;
    border-radius: 50%;
    cursor: pointer;
  }
   .team-icon li a:hover{
    color:#dedede;
    transition: all 0.25s ease-in-out;
  }

}
.careers-carousel-wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden; /* Hide any overflow from scrolling */
  background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),     /* Strong yellow on the left */
  rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
  transparent 100%            /* Fully transparent on the far right */
);
}

.careers-image-carousel-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  white-space: nowrap;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),     /* Strong yellow on the left */
  rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
  transparent 100%            /* Fully transparent on the far right */
);

}

.careers-image-carousel-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),     /* Strong yellow on the left */
  rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
  transparent 100%            /* Fully transparent on the far right */
);
}

.careers-carousel-image-group {
  display: inline-flex;
  gap: 15px; /* Space between images */
  padding: 20px; /* Adds padding around the carousel */
}

.careers-carousel-image {
  width: 300px; /* Adjust width for each image */
  height: 400px; /* Set a consistent height for all images */
  object-fit: cover;
  flex-shrink: 0; /* Prevent images from shrinking */
  border-radius: 8px; /* Optional: adds rounded corners to images */
}

/* Navigation buttons */
.careers-carousel-control {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.careers-carousel-control.prev {
  left: 10px; /* Position on the left over the images */
}

.careers-carousel-control.next {
  right: 10px; /* Position on the right over the images */
}

@media (max-width: 768px) {
.careers-carousel-wrapper {
  max-width: 100%; /* Allow the carousel to take full width */
  padding: 10px; /* Add padding for better spacing */
  background: linear-gradient(
    to right,
    rgba(255, 215, 0, 0.6), /* Strong yellow on the left */
    rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
    transparent 100% /* Fully transparent on the far right */
  );
}

.careers-image-carousel-container {
  gap: 10px; /* Reduce spacing for smaller screens */
  padding: 10px; /* Reduce padding around the carousel */
  overflow-x: auto; /* Ensure scrolling works properly */
  scroll-behavior: smooth; /* Smooth scrolling for better experience */
}

.careers-carousel-image-group {
  padding: 10px; /* Reduce padding around images */
  gap: 10px; /* Reduce gap between images */
}

.careers-carousel-image {
  width: 200px; /* Reduce width for smaller screens */
  height: 300px; /* Adjust height proportionally */
  border-radius: 5px; /* Slightly smaller rounded corners */
}

.careers-carousel-control {
  width: 30px; /* Reduce size of navigation buttons */
  height: 30px;
  font-size: 18px; /* Adjust font size for smaller buttons */
}

.careers-carousel-control.prev {
  left: 5px; /* Adjust position for smaller screens */
}

.careers-carousel-control.next {
  right: 5px; /* Adjust position for smaller screens */
}
}
@media (max-width: 768px) {
.careers-carousel-wrapper {
  max-width: 100%; /* Allow full width for smaller screens */
  padding: 20px; /* Add some padding */
  background: linear-gradient(
    to bottom,
    rgba(255, 215, 0, 0.6), /* Strong yellow on top */
    rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
    transparent 100% /* Fully transparent at the bottom */
  );
}

.careers-image-carousel-container {
  display: flex;
  flex-direction: column; /* Stack images vertically */
  align-items: center; /* Center align items */
  gap: 20px; /* Add space between items */
  overflow: visible; /* Remove horizontal scroll */
  padding: 0; /* Remove extra padding */
}

.careers-carousel-image-group {
  display: flex;
  flex-direction: column; /* Ensure column stacking */
  align-items: center; /* Center align images */
  gap: 15px; /* Space between stacked images */
}

.careers-carousel-image {
  width: 80%; /* Adjust width to fit smaller screens */
  height: auto; /* Allow height to adjust proportionally */
  border-radius: 8px; /* Retain rounded corners */
}

/* Navigation buttons (optional for vertical layout) */
.careers-carousel-control {
  display: none; /* Hide navigation buttons in column layout */
}
}


.ContactUsIma{
  width:100%;
  max-height: 500px;
  object-fit: cover;
}

/* Container for the image and text overlay */
.careers-header-overlay {
  position: relative;
  width: 100%;
  max-width: 1620px; /* Optional: limit the container width */
  margin: 0 auto;
  overflow: hidden;
}

/* Background image styling */
.careers-header-image {
  width: 100%; /* Full width */
  max-height: 450px; /* Set the maximum height */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}


/* Overlay container for text */
.careers-overlay-text {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),     /* Strong yellow on the left */
  rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
  transparent 100%            /* Fully transparent on the far right */
); /* Semi-transparent yellow overlay */
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
text-align: left;
padding: 20px;
color: white; /* Text color */
}

/* Styling for heading */
.careers-overlay-text h1 {
font-size: 2.5rem;
font-weight: bold;
color: #ffffff;
margin-bottom: 10px;
}

/* Styling for subtext */
.careers-overlay-text p {
font-size: 1.2rem;
color:black;
font-weight: bolder;
text-align: left;
}


.col-sm-600.text-center {
text-align: left; /* Align text to the left */
}

.All-Careers-page{
 background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),     /* Strong yellow on the left */
  rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
  transparent 100%            /* Fully transparent on the far right */
);
}

@media (max-width: 1920px) {
.careers-header-overlay {
  position: relative;
  width: 100%;
  max-width: 1620px; /* Optional: limit the container width */
  margin: 0 auto;
  overflow: hidden;
}

/* Background image styling */
.careers-header-image {
  width: 100%; /* Full width */
  max-height: 450px; /* Set the maximum height */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}


/* Overlay container for text */
.careers-overlay-text {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),     /* Strong yellow on the left */
  rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
  transparent 100%            /* Fully transparent on the far right */
); /* Semi-transparent yellow overlay */
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
text-align: left;
padding: 20px;
color: white; /* Text color */
}

/* Styling for heading */
.careers-overlay-text h1 {
font-size: 2.5rem;
font-weight: bold;
color: #ffffff;
margin-bottom: 10px;
}

/* Styling for subtext */
.careers-overlay-text p {
font-size: 1.2rem;
color:black;
font-weight: bolder;
text-align: left;
}


.col-sm-600.text-center {
text-align: left; /* Align text to the left */
}

.All-Careers-page{
 background: linear-gradient(
  to right,
  rgba(255, 215, 0, 0.6),     /* Strong yellow on the left */
  rgba(255, 255, 224, 0.3) 70%, /* Light yellow-white in the middle */
  transparent 100%            /* Fully transparent on the far right */
);
}
}




/*******************Benefits************************/
.hero-section {
position: relative;
overflow: hidden;
height: 550px; /* Keep the fixed height */
}

.hero-image {
width: 100%;
 /* Keep the fixed height */
object-fit: cover; /* Ensure the image covers the container */
object-position: bottom; /* Focus on the bottom of the image */
}

.hero-overlay {
position: absolute;
bottom: 2%; /* Position overlay towards the bottom of the hero section */
left: 50%;
transform: translate(-50%, 0); /* Center horizontally */
text-align: center;
color: #fff; /* White text for better contrast */
background-color: rgba(0, 0, 0, 0.3); /* Lighter semi-transparent background */
padding: 20px;
border-radius: 10px; /* Optional: rounded corners */
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}
.hero-overlay h1 {
font-size: 2.5em; /* Increase size for emphasis */
font-weight: bold; /* Make it bolder */
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add shadow for text */
}

.hero-overlay p {
font-size: 1.2em; /* Adjust size for better readability */
margin-top: 10px; /* Space between heading and paragraph */
}

.about-us-container {
font-family: 'Nobel', sans-serif;
font-size: 1.125rem; /* Adjusted for larger text */
}

@media (max-width:1920px){
.hero-section {
  position: relative;
  overflow: hidden;
  height: 550px; /* Keep the fixed height */
}

.hero-image {
  width: 100%;
   /* Keep the fixed height */
  object-fit: cover; /* Ensure the image covers the container */
  object-position: bottom; /* Focus on the bottom of the image */
}

.hero-overlay {
  position: absolute;
  bottom: 2%; /* Position overlay towards the bottom of the hero section */
  left: 50%;
  transform: translate(-50%, 0); /* Center horizontally */
  text-align: center;
  color: #fff; /* White text for better contrast */
  background-color: rgba(0, 0, 0, 0.3); /* Lighter semi-transparent background */
  padding: 20px;
  border-radius: 10px; /* Optional: rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}
.hero-overlay h1 {
  font-size: 2.5em; /* Increase size for emphasis */
  font-weight: bold; /* Make it bolder */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add shadow for text */
}

.hero-overlay p {
  font-size: 1.2em; /* Adjust size for better readability */
  margin-top: 10px; /* Space between heading and paragraph */
}

.about-us-container {
  font-family: 'Nobel', sans-serif; /* Font style */
  font-size: 1.125rem; /* Adjusted for larger text */
}
}

